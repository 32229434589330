import { GET, POST, PUT, DELETE, SEARCH } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
import { PER_PAGE } from '../../helpers/Constants';

import { isUnauthorized } from '../Login/login.store';

const FILE = 'FILE://DOCTOR_MANAGEMENT';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_DOCTOR_LIST = generateActionType('GET_DOCTOR_LIST');
const GET_DOCTOR_DETAILS = generateActionType('GET_DOCTOR_DETAILS');
const BEFORE_GET_DOCTOR_LIST = generateActionType('BEFORE_GET_DOCTOR_LIST');
const GET_DOCTOR_LIST_PAGINATION = generateActionType('GET_DOCTOR_LIST_PAGINATION');
const STORE_KEYWORDS = generateActionType('STORE_KEYWORDS');
const STORE_PARAMS = generateActionType('STORE_PARAMS');
const GET_PATIENT_LIST = generateActionType('GET_PATIENT_LIST');
const BEFORE_GET_PATIENT_LIST = generateActionType('BEFORE_GET_PATIENT_LIST');
const GET_CLINIC_LIST = generateActionType('GET_CLINIC_LIST');
const UPDATE_LIST = generateActionType('UPDATE_LIST');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_ALL = generateActionType('CLEAR_ALL');
const CLEAR_DETAILS = generateActionType('CLEAR_DETAILS');
const CLEAR_STATUS = generateActionType('CLEAR_STATUS');

// Reducer
const initialState = {
	initialList: true,
	fetching: false,
	dataLoader: true,
	detailsFetching: false,
	doctorList: [],
	patientList: [],
	doctorDetails: {
		data: {},
		fetching: false,
		noData: false,
	},
	listOffset: 0,
	keywords: '',
	statusCode: 0,
	isMessage: false,
	messageType: '',
	messageContent: {},
	noOfResults: 0,
	totalResults: 0,
	clinic: {
		list: [],
		fetching: false,
	},
	isClinicAdminADoctor: false,
};

export function clearDoctorDetails() {
	return function (dispatch) {
		dispatch({ type: CLEAR_ALL });
	};
}

export function clearStatus() {
	return function (dispatch) {
		dispatch({ type: CLEAR_STATUS });
	};
}

export function clearDetails() {
	return function (dispatch) {
		dispatch({ type: CLEAR_DETAILS });
	};
}
export function searchKeyword(searchKeywords = '') {
	return function (dispatch) {
		dispatch({ type: STORE_KEYWORDS, keyword: searchKeywords });
	};
}

export function setParamsAction(params = []) {
	return function (dispatch) {
		dispatch({ type: STORE_PARAMS, params });
	};
}

export function updateList(data = {}, headers = {}) {
	const { requestType = '', id = '' } = data;
	const getConfig = { url: `doctors/${id}`, headers };
	return async function (dispatch) {
		try {
			if (id === '' && requestType === '') return true;
			const { data = {} } = await GET(getConfig);
			dispatch({ type: UPDATE_LIST, data, requestType, id });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function getDoctorListAction(data = {}, headers = {}) {
	const {
		limit = PER_PAGE,
		offset = 0,
		pagination = false,
		searchKeyword = '',
		initialList = true,
		params = '',
	} = data;

	const initialLists = initialList;

	const getConfig = {
		params,
		url: data.noLimit
			? `doctors?limit=1000&offset=${offset}&search=${searchKeyword}`
			: `doctors?limit=${limit}&offset=${offset}&search=${searchKeyword}`,
		headers,
	};

	return async function (dispatch) {
		try {
			dispatch({ type: STORE_KEYWORDS, searchKeyword });
			dispatch({ type: STORE_PARAMS, params });
			// initialLists = !pagination;
			const type = pagination ? GET_DOCTOR_LIST_PAGINATION : GET_DOCTOR_LIST;

			const { data = {} } = await GET(getConfig);
			const { results = {}, noOfResults = 0, totalResults = 0 } = data;
			dispatch({
				type,
				data: results,
				noOfResults,
				totalResults,
				offset,
				initialList: initialLists,
				dataLoader: false,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({
				type: GET_DOCTOR_LIST,
				data,
				listOffset: 0,
				initialList: true,
				dataLoader: false,
			});
		}
	};
}

export function getDoctorDetails(id = 0) {
	const getConfig = { url: `doctors/${id}` };
	const detailsFetching = true;
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);

			dispatch({ type: GET_DOCTOR_DETAILS, data, detailsFetching });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_DOCTOR_DETAILS, data, detailsFetching });
		}
	};
}

export function getPatientList(keyword = '', headers = {}) {
	const getConfig = { url: `discrete/patients?limit=100&offset=0&search=${keyword}`, headers };
	return async function (dispatch) {
		try {
			dispatch({ type: BEFORE_GET_PATIENT_LIST, patientFetching: true });
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_PATIENT_LIST, data, patientFetching: false });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_PATIENT_LIST, data, patientFetching: false });
		}
	};
}

export function linkPatientToDoctor(id = '', datas = [], headers = {}) {
	const postConfig = { data: { patientIdArray: datas }, url: `doctors/${id}/patient`, headers };
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			dispatch(updateList({ requestType: 'edit', id }, headers));
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Patient successfully linked to the doctor', key: '' } },
				})
			);
			// dispatch(getDoctorListAction());
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function unLinkPatientToDoctor(id = '', datas = [], headers = {}) {
	const delConfig = { data: { patientIdArray: datas }, url: `doctors/${id}/patient`, headers };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Patient successfully unlinked to the doctor', key: '' },
					},
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function generateDoctorInviteCode(doctorId, prefixData, headers = {}) {
	const postConfig = {
		data: { doctorId, isPremium: true },
		url: `invite/doctor`,
		headers,
	};
	if (prefixData.length > 0) {
		postConfig.data.prefix = prefixData;
	}
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			dispatch(updateList({ requestType: 'edit', doctorId }, headers));
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Invite code is generated successfully', key: '' },
					},
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function clinicSearch(searchKeyword = '') {
	const getConfig = { url: `clinics?limit=50&offset=0&search=${searchKeyword}` };
	return async function (dispatch) {
		if (searchKeyword === '') {
			dispatch({ type: GET_CLINIC_LIST, results: [] });
			return;
		}
		try {
			const { data = {} } = await SEARCH(getConfig);
			const { results = [] } = data;
			dispatch({ type: GET_CLINIC_LIST, results });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			return error;
			// dispatch(errorHandling(error));
		}
	};
}

export function clearClinicSearch() {
	return async function (dispatch) {
		dispatch({ type: GET_CLINIC_LIST, results: [] });
	};
}

export function createDoctor(datas = {}, headers = {}, isClinicAdminADoctor) {
	const postConfig = { data: { ...datas }, url: `doctors`, headers };
	delete postConfig.data.isInviteCode;
	delete postConfig.data.prefix;

	return async function (dispatch) {
		try {
			const { status = 0, data = {} } = await POST(postConfig);

			if (datas && datas.isInviteCode) {
				dispatch(generateDoctorInviteCode(data.id, datas.prefix, headers));
			}
			dispatch(getDoctorListAction({}, headers));
			dispatch({ type: GET_STATUS, status, isClinicAdminADoctor });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully added', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function editDoctor(datas = {}, id = 0, headers = {}, isClinic = false) {
	const filterDatas = { ...datas };
	delete filterDatas.email;
	delete filterDatas.phone;
	delete filterDatas.countryCode;
	delete filterDatas.isClinicAdmin;
	delete filterDatas.isInviteCode;
	delete filterDatas.prefix;
	// delete filterDatas.clinicUid;

	const postConfig = { data: filterDatas, url: `doctors/${id}`, headers };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);
			// dispatch(getDoctorListAction());
			if (datas && datas.isInviteCode) {
				dispatch(generateDoctorInviteCode(id, datas.prefix, headers));
			}
			if (!isClinic) {
				dispatch(updateList({ requestType: 'edit', id }, headers));
				dispatch({ type: GET_STATUS, status });
				dispatch(
					showMessage({
						isMessage: true,
						messageType: 'Success',
						messageContent: { 0: { message: 'Record successfully updated', key: '' } },
					})
				);
			}
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

function doctorManagementReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
				isClinicAdminADoctor: action.isClinicAdminADoctor || false,
			};
		}
		case STORE_KEYWORDS:
			return {
				...state,
				keywords: action.searchKeyword,
			};
		case STORE_PARAMS:
			return {
				...state,
				params: action.params,
			};

		case GET_CLINIC_LIST: {
			return {
				...state,
				clinic: {
					list: action.results,
				},
			};
		}
		case BEFORE_GET_DOCTOR_LIST:
			return {
				...state,
				dataLoader: action.dataLoader,
			};

		case UPDATE_LIST: {
			const { data = {}, requestType = '', id = '' } = action;
			const { patients = [] } = data;
			const { doctorList = [] } = state;
			const patientCount = Array.isArray(patients) ? patients.length : 0;
			const datas = { ...data, patientCount };

			if (Object.keys(data).length !== 0 && id !== '' && requestType !== '') {
				doctorList.forEach((d, i) => {
					if (d.id === id) {
						doctorList[i] = datas;
						return true;
					}
				});
			}

			return { ...state };
		}

		case GET_DOCTOR_LIST: {
			return {
				...state,
				doctorList: action.data,
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: 0,
				noOfResults: 1,
				totalResults: action.totalResults,
				/* statusCode: 0, */
			};
		}
		case GET_DOCTOR_LIST_PAGINATION: {
			const { data } = action;
			return {
				...state,
				doctorList: [...state.doctorList, ...data],
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: action.offset,
				noOfResults: action.noOfResults,
				totalResults: action.totalResults,
			};
		}
		case GET_DOCTOR_DETAILS:
			return {
				...state,
				doctorDetails: {
					data: action.data,
					fetching: true,
					// noData,
				},
			};
		case BEFORE_GET_PATIENT_LIST:
			return {
				...state,
				patientFetching: action.patientFetching,
			};
		case GET_PATIENT_LIST:
			return {
				...state,
				patientList: action.data,
				patientFetching: action.patientFetching,
			};

		case CLEAR_DETAILS:
			return {
				...state,
				doctorDetails: {
					data: {},
					fetching: false,
					noData: false,
				},
			};
		case CLEAR_STATUS:
			return {
				...state,
				statusCode: 0,
				isClinicAdminADoctor: false,
			};
		case CLEAR_ALL:
			return {
				...state,
				initialList: true,
				fetching: false,
				dataLoader: true,
				detailsFetching: false,
				doctorList: [],
				patientList: [],
				doctorDetails: {
					data: {},
					fetching: false,
					noData: false,
				},
				listOffset: 0,
				keywords: '',
				statusCode: 0,
				isMessage: false,
				messageType: '',
				messageContent: {},
				noOfResults: 0,
				totalResults: 0,
				clinic: {
					list: [],
					fetching: false,
				},
			};

		default:
			return state;
	}
}

export default doctorManagementReducer;
