import styled from 'styled-components';
import { fontBold, fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const TableData = styled.td`
	text-align: left;
	font-weight: normal;
	color: ${colors.BlueBase};
	padding: 14px 15px 14px 33px;
	border-bottom: 1px solid ${colors.Border2};
	transition: ease all 0.4s;
	width: ${(props) => props.width};
	border-bottom: ${(props) => props.borderBottom}!important;
	padding-left: ${(props) => props.paddingLeft} !important;
	max-width: ${(props) => props.maxWidth};
	${({ neglectBtn }) =>
		!neglectBtn &&
		`button {
		width: 30px;
		height: 30px;
		border-radius: 6px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: ease-in all 0.3s;
		color: ${colors.Grey6};
		font-size: 16px;
		:hover,
		:focus {
			background: #c8dbe9;
		}
	}`}
	img {
		min-width: ${({ neglectImgWidth }) => (neglectImgWidth ? 0 : '30px')};
	}
	${({ Nowrap }) => Nowrap && `white-space:nowrap;`};
	${({ TextRight }) => TextRight && `text-align: right;`};
	${({ BoldText }) => BoldText && `font-family:${fontMedium.familyName};`};
	${({ NoPadding }) => NoPadding && `padding:0px !important;`};
	${({ NoTopNoBottom }) =>
		NoTopNoBottom &&
		`
		padding-top:0px;
		padding-bottom:'0px`};
	${({ TextEllipsis }) =>
		TextEllipsis &&
		`overflow: hidden;
		max-width: 250px;
		text-overflow: ellipsis;`};
	${({ PatientName }) =>
		PatientName &&
		`font-family:${fontMedium.familyName};
		font-size:16px;
			@media screen and (max-width: 1200px) {
				font-size:14px;
				font-family:${fontBold.familyName};
			}
		`};
	${({ TextPrimary }) =>
		TextPrimary &&
		`color: ${colors.BlueBase}!important;
		`};
	${({ TextLink }) =>
		TextLink &&
		`color: ${colors.BlueLight} !important;
		`};
	${({ TextGrey }) =>
		TextGrey &&
		`color: ${colors.Grey6} !important;
		`};
	${({ BoldPrimary }) =>
		BoldPrimary &&
		`font-family:${fontMedium.familyName};
		font-size:16px;
		color: ${colors.BlueBase};
			@media screen and (max-width: 1200px) {
				font-size:14px;
				font-family:${fontBold.familyName};
			}
        `};

	${({ subTitle }) =>
		subTitle &&
		`p {
                margin-top: 4px;
                font-size: 13px;
                margin-left: 42px
            }
        `};
	${({ buttonAuto }) =>
		buttonAuto &&
		`button {
                width: auto;
                height: auto;
                border: 1px solid #536DFE !important;
                color: #fff;
                font-size: 12px;
                border: none;
            }
        `};

	${({ DateandTime }) =>
		DateandTime &&
		`text-align:right; font-size:12px; color: #8A91A3!important; white-space:nowrap;`};
	padding-right: ${(props) => props.paddingRight};
`;

export default TableData;
