import styled from 'styled-components';
import { fontMedium, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const FormLabel = styled.label`
	display: inline-block;
	width: 100%;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 19px;
	box-sizing: border-box;
	font-family: ${fontMedium.familyName};

	color: ${colors.Black};
	${({ floatingLabel }) =>
		floatingLabel &&
		`position: absolute;
		pointer-events: none;
		left: 20px;
		top: 14px;
		transition: 0.3s ease all;
		color: #545454;
		font-family: ${fontRegular.familyName};
		opacity: 0.5;
		@media screen and (max-width: 767px){
			font-size: 14px;
			left: 15px;
		}
    `}
`;

export default FormLabel;
