import { GET } from '../../service/HttpService';
import { isUnauthorized } from '../Login/login.store';

const FILE = 'FILE://DASHBOARD/STATISTICS';

const generateActionType = (type) => `${FILE}/${type}`;

const SAVE_STATISTICS_RECORD = generateActionType('SAVE_STATISTICS_RECORD');
const FETCH_CLINICAL_NOTES_ALERTS = generateActionType('FETCH_CLINICAL_NOTES_ALERTS');
const SAVE_CLINICAL_NOTES_ALERTS = generateActionType('SAVE_CLINICAL_NOTES_ALERTS');
const FETCH_CLINICAL_NOTIFICATIONS = generateActionType('FETCH_CLINICAL_NOTIFICATIONS');
const SAVE_CLINICAL_NOTIFICATIONS = generateActionType('SAVE_CLINICAL_NOTIFICATIONS');
const FETCH_CLINIC_PATIENTS_SUMMARY = generateActionType('FETCH_CLINIC_PATIENTS_SUMMARY');
const SAVE_CLINIC_PATIENTS_SUMMARY = generateActionType('SAVE_CLINIC_PATIENTS_SUMMARY');
const FETCH_CLINIC_INVITEE_SUMMARY = generateActionType('FETCH_CLINIC_INVITEE_SUMMARY');
const SAVE_CLINIC_INVITEE_SUMMARY = generateActionType('SAVE_CLINIC_INVITEE_SUMMARY');

export function saveStatistics(data) {
	return {
		type: SAVE_STATISTICS_RECORD,
		data,
	};
}

export async function getStatisticsData({ url }) {
	const statisticsConfig = { url };
	const response = await GET(statisticsConfig);
	return response;
}

export async function getAlerts(args) {
	const alertsConfig = { params: { ...args }, url: '/clinical-notes/summary' };
	const response = await GET(alertsConfig);
	return response;
}

export async function getNotifications(args) {
	const notificationConfig = { params: { ...args }, url: '/clinical/notification' };
	const response = await GET(notificationConfig);
	return response;
}

export async function getClinicPatients(args) {
	const clinicConfig = { params: { ...args }, url: '/clinics/patients' };
	const response = await GET(clinicConfig);
	return response;
}

export async function getClinicStatistics(args, headers = {}) {
	const clinicConfig = { params: { ...args }, url: '/invitees', headers };
	const response = await GET(clinicConfig);
	console.log('invitee', response);
	return response;
}

export async function getClinicStatisticsGraph(args, headers = {}) {
	const clinicConfig = { params: { ...args }, url: '/invite/summary', headers };
	const response = await GET(clinicConfig);
	console.log('invitee--summary', response);
	return response;
}

export function getClinicalNotesAlerts(args) {
	return async function (dispatch) {
		try {
			const { data = {} } = await getAlerts(args);
			dispatch({ type: SAVE_CLINICAL_NOTES_ALERTS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_CLINICAL_NOTES_ALERTS, isFetching: false });
			console.log(' Error in getClinicalNotesAlerts() ', error);
		}
	};
}

export function getClinicalNotifications(args) {
	return async function (dispatch) {
		try {
			const { data = {} } = await getNotifications(args);
			dispatch({ type: SAVE_CLINICAL_NOTIFICATIONS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_CLINICAL_NOTIFICATIONS, isFetching: false });
			console.log(' Error in getClinicalNotifications() ', error);
		}
	};
}

export function getClinicPatientsSummary(args) {
	return async function (dispatch) {
		try {
			const { data = {} } = await getClinicPatients(args);
			dispatch({ type: SAVE_CLINIC_PATIENTS_SUMMARY, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_CLINIC_PATIENTS_SUMMARY, isFetching: false });
			console.log(' Error in getClinicPatientsSummary() ', error);
		}
	};
}

export function getClinicStatisticsSummary({ headers = {}, limiters }) {
	return async function (dispatch) {
		try {
			const [stats, graph] = await Promise.all([
				getClinicStatistics(limiters, headers),
				getClinicStatisticsGraph({}, headers),
			]);
			const { data: statsData = {} } = stats;
			const { data: graphsData = {} } = graph;
			dispatch({ type: SAVE_CLINIC_INVITEE_SUMMARY, data: { ...statsData, summary: graphsData } });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_CLINIC_INVITEE_SUMMARY, isFetching: false });
			console.log(' Error in getClinicStatisticsSummary() ', error);
		}
	};
}

export const initialState = {
	statistics: {
		fetching: false,
		data: {},
	},
	clinicalNotesAlerts: {
		loading: true,
		data: {},
	},
	clinicalNotifications: {
		loading: true,
		data: {},
	},
	clinicPatients: {
		loading: true,
		data: {},
		latestStatistics: {
			fetching: true,
			data: {},
		},
	},
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SAVE_STATISTICS_RECORD:
			return {
				...state,
				statistics: {
					data: action.data,
					fetching: false,
				},
			};
		case FETCH_CLINICAL_NOTES_ALERTS:
			return {
				...state,
				clinicalNotesAlerts: {
					...state.clinicalNotesAlerts,
					loading: action.isFetching,
				},
			};
		case SAVE_CLINICAL_NOTES_ALERTS:
			return {
				...state,
				clinicalNotesAlerts: {
					...state.clinicalNotesAlerts,
					data: action.data,
					loading: false,
				},
			};
		case FETCH_CLINICAL_NOTIFICATIONS:
			return {
				...state,
				clinicalNotifications: {
					...state.clinicalNotifications,
					loading: action.isFetching,
				},
			};
		case SAVE_CLINICAL_NOTIFICATIONS:
			return {
				...state,
				clinicalNotifications: {
					...state.clinicalNotifications,
					data: action.data,
					loading: false,
				},
			};
		case FETCH_CLINIC_PATIENTS_SUMMARY:
			return {
				...state,
				clinicPatients: {
					...state.clinicPatients,
					data: {},
					loading: false,
				},
			};
		case SAVE_CLINIC_PATIENTS_SUMMARY:
			return {
				...state,
				clinicPatients: {
					...state.clinicPatients,
					data: action.data,
					loading: false,
				},
			};
		case FETCH_CLINIC_INVITEE_SUMMARY:
			return {
				...state,
				clinicPatients: {
					...state.clinicPatients,
					latestStatistics: {
						...state.clinicPatients.latestStatistics,
						fetching: action.isFetching,
					},
				},
			};
		case SAVE_CLINIC_INVITEE_SUMMARY:
			return {
				...state,
				clinicPatients: {
					...state.clinicPatients,
					latestStatistics: {
						...state.clinicPatients.latestStatistics,
						fetching: false,
						data: action.data,
					},
				},
			};
		default:
			return state;
	}
}

export default reducer;
