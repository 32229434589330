import { DELETE, GET, PATCH, POST, PUT } from '../../service/HttpService';

import { isUnauthorized } from '../Login/login.store';
import { showSnackbar } from '../../components/Snackbar/snackbar.store';

import { snackBarErrorTypes } from '../../helpers/Constants';
import { extractMessageFrom } from '../../helpers/Utilities';
// import { getInviteesListAction } from '../PatientInvitation/patient.invitation.store';

const FILE = 'FILE://LOGIN';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_PATIENT_LISTS = generateActionType('GET_PATIENT_LISTS');
const SAVE_PATIENT_LISTS = generateActionType('SAVE_PATIENT_LISTS');
const CLEAR_PATIENT_LISTS = generateActionType('CLEAR_PATIENT_LISTS');
export const BULK_PATIENT_IMPORT = generateActionType('BULK_PATIENT_IMPORT');
const RESET_BULK_IMPORT_PROGRESS = generateActionType('RESET_BULK_IMPORT_PROGRESS');
const UPDATE_PATIENT_LIST = generateActionType('UPDATE_PATIENT_LIST');

// Action Creators
export async function getPatientList(args, url, headers = {}) {
	const patientConfig = { params: { ...args }, url: url || '/patients', headers };
	const response = await GET(patientConfig);
	return response;
}

export async function getDoctorList(args, url, headers = {}) {
	const doctorConfig = { params: { ...args }, url: url || '/doctors', headers };
	const response = await GET(doctorConfig);
	return response;
}

export async function getTemplate() {
	const templateConfig = { url: '/invite/csv-template' };
	const response = await GET(templateConfig);
	return response;
}

export async function specialCategory({ patientId, isSpecialCategory }) {
	const patientConfig = { data: { isSpecialCategory }, url: `/patients/${patientId}/special` };
	const response = await PATCH(patientConfig);
	return response;
}

export async function getUnReferralDoctorList(params = {}) {
	const patientConfig = {
		url: `doctors?limit=1000&offset=0`,
		params: params.search && params.search.length > 0 ? params : null,
	};
	const response = await GET(patientConfig);
	return response;
}

export async function addNewReferral(params = {}) {
	const patientConfig = {
		url: `doctors/assign-doctor`,
		data: params,
	};
	const response = await POST(patientConfig);
	return response;
}

export async function removeReferral(doctorId, patientId) {
	const patientConfig = {
		url: `doctors/assign-doctor/${doctorId}`,
		data: { patientId },
	};
	const response = await DELETE(patientConfig);
	return response;
}

export async function removeReminder(reminderId) {
	const patientConfig = {
		url: `followups/${reminderId}`,
	};
	const response = await DELETE(patientConfig);
	return response;
}

export async function addNewReminder(reminderData) {
	delete reminderData.date;
	delete reminderData.time;
	const patientConfig = {
		url: `followups`,
		data: { ...reminderData },
	};
	const response = await POST(patientConfig);
	return response;
}

export async function updateReminder(reminderData, reminderId) {
	delete reminderData.date;
	delete reminderData.time;
	delete reminderData.id;
	delete reminderData.creatorRole;
	delete reminderData.createdBy;
	const patientConfig = {
		url: `followups/${reminderId}`,
		data: { ...reminderData },
	};
	const response = await PUT(patientConfig);
	return response;
}

export async function bulkInvite({ data }) {
	const importConfig = { data, url: `/invite/import` };
	const response = await POST(importConfig);
	return response;
}

export async function fileUpload(args) {
	const uploadConfig = {
		...args,
		appConfig: { baseURL: '', doNotNeedAuthorizationHeader: true },
		headers: { 'Content-Type': 'Multipart/form-data' },
	};
	const response = await POST(uploadConfig);
	return response;
}

export function resetProgress() {
	return { type: RESET_BULK_IMPORT_PROGRESS };
}

export function updatePatientList(data) {
	return { type: UPDATE_PATIENT_LIST, data };
}

export function getPatients({ override, ...args }, headers = {}) {
	return async function (dispatch) {
		dispatch({ type: GET_PATIENT_LISTS, fetch: true });
		try {
			const { data = {} } = await getPatientList(args, '', headers);
			dispatch({ type: SAVE_PATIENT_LISTS, data, override });
		} catch (error) {
			dispatch({ type: GET_PATIENT_LISTS, fetch: false });
			if (isUnauthorized(dispatch, error)) return;
			console.log('getPatients error = ', error);
		}
	};
}

export function markSpecialCategory({ limitersConfig, selectedPatient }) {
	return async function (dispatch) {
		dispatch({ type: GET_PATIENT_LISTS, fetch: true });
		try {
			await specialCategory(selectedPatient);
			dispatch(getPatients(limitersConfig));
		} catch (error) {
			dispatch(getPatients(limitersConfig));
			if (isUnauthorized(dispatch, error)) return;
			console.log('markSpecialCategory error = ', error);
		}
	};
}

export async function uploadPatientDetails({ doctorUid, file, dispatch }) {
	try {
		dispatch({ type: BULK_PATIENT_IMPORT, inProgress: true, completed: false });
		const awsConfig = {
			url: 'invite/files',
			data: { filename: 'patients.csv', contentType: 'string' },
		};
		const { data: { fields = {}, url } = {} } = await POST(awsConfig);

		const { key } = fields;
		const formData = new FormData();

		Object.keys(fields).forEach((key) => {
			formData.append(key, fields[key]);
		});

		formData.append('file', file);

		await fileUpload({ data: formData, url });
		const response = await bulkInvite({ data: { doctorUid, csvKey: key } });

		return response;
	} catch (error) {
		if (isUnauthorized(dispatch, error)) return;
		dispatch({ type: BULK_PATIENT_IMPORT, inProgress: false, completed: false });
		dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
		return {};
	}
}

export function clearPatients() {
	return async function (dispatch) {
		dispatch({ type: CLEAR_PATIENT_LISTS, fetch: true });
	};
}

// Reducer
const initialState = {
	fetching: false,
	lists: [],
	noOfResults: 0,
	totalResults: 0,
	offset: 0,
	error: {},
	bulkUpload: {
		inProgress: false,
		completed: false,
	},
};

function patientReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_PATIENT_LIST: {
			const { patientId, isSpecial } = action.data;
			const { lists = [] } = state;
			const patient = lists && lists.find((x) => x.patientId === patientId);
			const patientIndex = lists && lists.findIndex((x) => x.patientId === patientId);

			if (patient && patientIndex >= 0) {
				lists[patientIndex] = {
					...patient,
					DoctorUserMap: { ...patient.DoctorUserMap, isSpecialCategory: isSpecial },
					isSpecialCategory: isSpecial,
				};
			}

			return {
				...state,
				lists,
			};
		}
		case RESET_BULK_IMPORT_PROGRESS:
			return {
				...state,
				bulkUpload: {
					...state.bulkUpload,
					inProgress: false,
					completed: false,
				},
			};
		case BULK_PATIENT_IMPORT:
			return {
				...state,
				bulkUpload: {
					...state.bulkUpload,
					inProgress: action.inProgress,
					completed: action.completed,
				},
			};
		case GET_PATIENT_LISTS:
			return {
				...state,
				fetching: action.fetch,
			};
		case SAVE_PATIENT_LISTS: {
			const { data = {}, override } = action;
			const { results = [], noOfResults = 0, totalResults = 0, offset = 0 } = data;
			return {
				...state,
				fetching: false,
				noOfResults,
				totalResults,
				offset,
				lists: override ? results : state.lists.concat(results),
			};
		}
		case CLEAR_PATIENT_LISTS:
			return {
				...state,
				fetching: false,
				lists: [],
				noOfResults: 0,
				totalResults: 0,
				offset: 0,
			};

		default:
			return state;
	}
}

export default patientReducer;
