import styled from 'styled-components';
import { fontBold } from '../../assets/styles/typography';
import { colors } from '../colors';

const Badge = styled.span`
	transition: all ease 0.4s;
	background: ${colors.BlueLight};
	color: ${colors.White};
	font-size: 13px;
	padding: 0 10px;
	min-width: 18px;
	height: 18px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	line-height: 12px;
	${({ rounded }) =>
		rounded &&
		`border-radius:20px;
        padding:4px 6px;
        `}

	${({ primaryDoctor }) =>
		primaryDoctor &&
		`border-radius:4px;
		color: #3E2F21;
        padding:1px, 5px, 1px, 5px;
		background:#E9ECFF;
		font-weight: 600;
font-size: 12px;
		// border:1px solid #6AB0F3;
        `}
		${({ coach }) =>
		coach &&
		`border-radius:4px;
		color: #3E2F21;
        padding:1px, 5px, 1px, 5px;
		background:#CBF7EC;
		font-weight: 600;
font-size: 12px;
		// border:1px solid #6AB0F3;
        `}
		
		${({ reminder }) =>
		reminder &&
		`border-radius:8px;
		color: #536DFE;
		font-weight: 600;
font-size: 15px;
        padding:1px, 5px, 1px, 5px;
		background:#FFF2E2;
		border:1px solid #FCB216;
		

        `}
	${({ primary }) =>
		primary &&
		`border-radius:4px;
		color: #3E2F21;
        padding:1px, 5px, 1px, 5px;
		background:#DCEEFF;
		font-weight: 600;
font-size: 12px;
		border:1px solid #6AB0F3;
        `}
	${({ referred }) =>
		referred &&
		`border-radius:4px;
		color: #3E2F21;
		font-weight: 600;
font-size: 12px;
        padding:1px, 5px, 1px, 5px;
		background:#FFF7E0;
		border:1px solid #FCB216;
        `}

		${({ deleted }) =>
		deleted &&
		`border-radius:4px;
		color: ${colors.White};
		font-weight: 600;
font-size: 12px;
        padding:1px, 5px, 1px, 5px;
		background:${colors.Red2};
        `}
		${({ active }) =>
		active &&
		`border-radius:4px;
		color: ${colors.White};
		font-weight: 600;
font-size: 12px;
        padding:1px, 5px, 1px, 5px;
		background:#5CDBAD;
        `}
		${({ inActive }) =>
		inActive &&
		`border-radius:4px;
		color: ${colors.White};
		font-weight: 600;
font-size: 12px;
        padding:1px, 5px, 1px, 5px;
		background:#FF7777;
        `}

	${({ Grey }) => Grey && `border-radius:20px; background: ${colors.Grey87}; `}
	${({ Pending }) => Pending && `min-width: 82px;border-radius:20px; background: ${colors.Red1}; `}
    ${({ Overweight }) =>
		Overweight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: ${colors.Red2}; color: ${colors.White}; `}
    ${({ UnderWeight }) =>
		UnderWeight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #4B4FD; color: ${colors.White}; `}
    ${({ HealthyWeight }) =>
		HealthyWeight &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #5CDBAD; color: ${colors.White};
        @media screen and (max-width: 600px) {
			text-align: center;
	height: auto;
	padding: 3px;
	font-size: 12px;
		}
        `}
     ${({ Obese }) =>
		Obese &&
		`min-width: 82px;border-radius:20px;  font-family: ${fontBold.familyName}; margin-bottom:10px; background: #FF7777; color: ${colors.White}; `}
	${({ Confirmed }) =>
		Confirmed && `min-width: 82px;border-radius:20px; background: ${colors.GreenBase}; `}
    ${({ Easy }) => Easy && `min-width: 82px;border-radius:20px; background: ${colors.Green2}; `}
    ${({ Hard }) => Hard && `min-width: 82px;border-radius:20px; background: ${colors.Red3}; `}
    ${({ Moderate }) =>
		Moderate && `min-width: 82px;border-radius:20px; background: ${colors.Red4}; `}
`;
export default Badge;
