import {
	// APICall,
	GET,
} from '../../service/HttpService';
import ChatService from './chat.service';

// eslint-disable-next-line import/no-cycle
import {
	deleteFromLocalStorage,
	isUnauthorized,
	saveToLocalStorageAsJSONString,
} from '../Login/login.store';

const FILE = 'FILE://CHAT';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const SAVE_USER_LISTED_CHANNELS = generateActionType('SAVE_USER_LISTED_CHANNELS');
const FETCH_TOKEN_FOR_INITIATING_CHAT = generateActionType('FETCH_TOKEN_FOR_INITIATING_CHAT');
const SAVE_CHAT_TOKEN = generateActionType('SAVE_CHAT_TOKEN');
const FAILED_TO_FETCH_CHAT_TOKEN = generateActionType('FAILED_TO_FETCH_CHAT_TOKEN');
const SAVE_CHAT_OBJECT = generateActionType('SAVE_CHAT_OBJECT');
const CONNECT_TO_A_CHANNEL = generateActionType('CONNECT_TO_A_CHANNEL');
const SAVE_MESSAGE_DETAILS = generateActionType('SAVE_MESSAGE_DETAILS');
const SAVE_LAST_MESSAGE_DETAILS = generateActionType('SAVE_LAST_MESSAGE_DETAILS');
const FETCHING_MESSAGE_HISTORY = generateActionType('FETCHING_MESSAGE_HISTORY');
const SAVE_MESSAGE_HISTORY = generateActionType('SAVE_MESSAGE_HISTORY');
const REARRANGE_CHANNELS = generateActionType('REARRANGE_CHANNELS');
const SORT_CHANNELS_BY_UNREAD_COUNT = generateActionType('SORT_CHANNELS_BY_UNREAD_COUNT');
const MARK_ALL_AS_READ = generateActionType('MARK_ALL_AS_READ');
const FETCH_CHANNELS = generateActionType('FETCH_CHANNELS');
const CLEAR_ACTIVE_CHANNEL = generateActionType('CLEAR_ACTIVE_CHANNEL');
const SAVE_LAST_MESSAGE_ONLY = generateActionType('SAVE_LAST_MESSAGE_ONLY');
const MEMBER_TYPING = generateActionType('MEMBER_TYPING');
const IS_USER_ONLINE = generateActionType('IS_USER_ONLINE');
const SAVE_LAST_MESSAGE_AND_REACHABILITY = generateActionType('SAVE_LAST_MESSAGE_AND_REACHABILITY');
const SAVE_CHANNEL_TO_BE_LOADED = generateActionType('SAVE_CHANNEL_TO_BE_LOADED');
const SAVE_NEW_CHANNELS_LIST = generateActionType('SAVE_NEW_CHANNELS_LIST');
const FETCH_NEW_CHANNELS_LIST = generateActionType('FETCH_NEW_CHANNELS_LIST');
const UPDATE_CHAT_PROFILE_NAMES = generateActionType('UPDATE_CHAT_PROFILE_NAMES');
const MEDIA_ATTACHMENT = generateActionType('MEDIA_ATTACHMENT');
const SAVE_MEDIA_ATTACHMENT_DATA = generateActionType('SAVE_MEDIA_ATTACHMENT_DATA');
const REMOVE_CHANNEL = generateActionType('REMOVE_CHANNEL');
const ADD_NEW_CHAT_PROFILE = generateActionType('ADD_NEW_CHAT_PROFILE');
const ADD_CHANNEL_TO_BE_LOAD = generateActionType('ADD_CHANNEL_TO_BE_LOAD');
const REMOVE_CHANNEL_TO_BE_LOAD = generateActionType('REMOVE_CHANNEL_TO_BE_LOAD');

const CHANNEL_MSG_UPDATED = generateActionType('CHANNEL_MSG_UPDATED');
const CHANNEL_READ_RECEIPT_UPDATED = generateActionType('CHANNEL_READ_RECEIPT_UPDATED');

// Action Creators
export function saveUserChannels(chatChannels) {
	return {
		type: SAVE_USER_LISTED_CHANNELS,
		chatChannels,
	};
}

export function saveAssets(args) {
	return {
		type: SAVE_MEDIA_ATTACHMENT_DATA,
		...args,
	};
}

export function saveNewChannels(channels) {
	return { type: SAVE_NEW_CHANNELS_LIST, channels };
}

export function addChannelToBeLoad(channel) {
	return { type: ADD_CHANNEL_TO_BE_LOAD, channel };
}

export function removeChannelToBeLoad() {
	return { type: REMOVE_CHANNEL_TO_BE_LOAD };
}

export function saveChatToken(data) {
	return { type: SAVE_CHAT_TOKEN, data };
}

export function attachMedia(attaching) {
	return { type: MEDIA_ATTACHMENT, attaching };
}

export function setFetchNextSet(isFetching) {
	return { type: FETCH_NEW_CHANNELS_LIST, isFetching };
}

export function saveChatObject(chat = null, channels = []) {
	return { type: SAVE_CHAT_OBJECT, chat, channels };
}

export function connectingToAChannel(isConnecting) {
	return { type: CONNECT_TO_A_CHANNEL, isConnecting };
}

export function fetchMessages(isFetching) {
	return { type: FETCHING_MESSAGE_HISTORY, isFetching };
}

export function saveMessageDetails(channel, messageObject) {
	return { type: SAVE_MESSAGE_DETAILS, channel, messageObject };
}

export function saveLastMessageOnly(Channel, Message) {
	return { type: SAVE_LAST_MESSAGE_ONLY, Channel, Message };
}

export function saveLastMessageDetails(messageInfo) {
	return { type: SAVE_LAST_MESSAGE_DETAILS, messageInfo };
}

export function reArrangeChannels() {
	return { type: REARRANGE_CHANNELS };
}

export function channelReadReceiptUpdated() {
	return { type: CHANNEL_READ_RECEIPT_UPDATED };
}

export function channelMessageUpdated() {
	return { type: CHANNEL_MSG_UPDATED };
}

export function sortByUnreadCount() {
	return { type: SORT_CHANNELS_BY_UNREAD_COUNT };
}

export function markAllAsRead() {
	return { type: MARK_ALL_AS_READ };
}

export function saveMessageHistory(messageInfo) {
	return { type: SAVE_MESSAGE_HISTORY, messageInfo };
}

export function fetchChannels(fetching) {
	return { type: FETCH_CHANNELS, fetching };
}

export function clearActiveChannel() {
	return { type: CLEAR_ACTIVE_CHANNEL };
}

export function memberIsTyping(member, isTyping) {
	return { type: MEMBER_TYPING, member, isTyping };
}

export function updateReachabilityStatus(channel, isOnline = false, isNotifiable = false) {
	return { type: IS_USER_ONLINE, channel, isOnline, isNotifiable };
}

export function saveLastMessageAndReachability(details = {}) {
	return { type: SAVE_LAST_MESSAGE_AND_REACHABILITY, details };
}

export function saveChannelToBeLoaded(userId) {
	return { type: SAVE_CHANNEL_TO_BE_LOADED, userId };
}

export function updateChatProfileNames(chatChannels) {
	return { type: UPDATE_CHAT_PROFILE_NAMES, chatChannels };
}

export function removeChannel(memberInfo) {
	return { type: REMOVE_CHANNEL, memberInfo };
}

export async function unregisterChatPush(channelType) {
	await ChatService.unsetPushRegistrationId(channelType);
}

export async function getChannelMessages(Channel, messageLimit = 30) {
	const { items = [] } = await ChatService.getMessages(Channel, messageLimit);
	const [Message = {}] = items;
	return Message;
}

export async function getMemberBySid(channel, memberSid) {
	const Member = await ChatService.findMember(channel, memberSid);
	return Member;
}

export async function getUser(member) {
	const userDescriptor = await ChatService.getUser(member);
	return userDescriptor;
}

export function shutDownTwilioClient() {
	ChatService.shutdown();
}

export async function chatProfiles() {
	const chatConfig = { url: 'chat/channels' };
	const response = await GET(chatConfig);
	return response;
}

export async function getBroadcastChannelList() {
	const chatConfig = {
		url: 'chat/broadcast',
		// baseUrl: 'https://49a08ab7-138c-4c2e-8f33-1c8b2000bef0.mock.pstmn.io/',
		// method: 'GET',
	};
	const response = await GET(chatConfig);
	return response;
}

function sortByLastMessageArrived(channels = []) {
	channels.sort((a, b) => {
		const second = new Date(b.lastMessage ? b.lastMessage.dateCreated : b.dateCreated);
		const first = new Date(a.lastMessage ? a.lastMessage.dateCreated : a.dateCreated);
		if (second < first) return -1;
		if (second > first) return 1;
		return 0;
	});

	return channels;
}

function getUnReadCount(channels = []) {
	channels.sort((channel1, channel2) => {
		const { lastConsumedMessageIndex: C1LastCosumedIndex = 0, lastMessage: C1Last = {} } = channel1;
		const { index: C1Index = 0 } = C1Last;
		const { lastConsumedMessageIndex: C2LastCosumedIndex = 0, lastMessage: C2sLst = {} } = channel2;
		const { index: C2Index = 0 } = C2sLst;

		if (C2Index - C2LastCosumedIndex < C1Index - C1LastCosumedIndex) return -1;
		if (C2Index - C2LastCosumedIndex > C1Index - C1LastCosumedIndex) return 1;
		return 0;
	});

	return channels;
}

export async function fetchTwilioToken() {
	const chatConfig = { url: '/chat/token' };
	const response = await GET(chatConfig);
	return response;
}

export function getChatToken() {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_TOKEN_FOR_INITIATING_CHAT, fetching: true });
			const { data = {} } = await fetchTwilioToken();
			// const chatConfig = { url: '/chat/token' };
			// const { data = {} } = await GET(chatConfig);
			dispatch({ type: SAVE_CHAT_TOKEN, data });
		} catch (error) {
			dispatch({ type: FAILED_TO_FETCH_CHAT_TOKEN, fetching: false });
		}
	};
}

export function updateChatProfiles() {
	return async function (dispatch) {
		try {
			const { data: profile = {} } = await chatProfiles();
			const { ChatChannel = [] } = profile;
			dispatch(updateChatProfileNames(ChatChannel));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			console.log('Error in updateChatProfiles() = ', error);
		}
	};
}

export function addNewUser(newChannel) {
	return async function (dispatch) {
		try {
			const { data: profile = {} } = await chatProfiles();
			const { ChatChannel = [] } = profile;
			dispatch({ type: ADD_NEW_CHAT_PROFILE, ChatChannel, newChannel });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			console.log('Error in addNewUser() = ', error);
		}
	};
}

// Reducer
const initialState = {
	isChannelMessageUpdated: false,
	isReadReceiptUpdated: false,
	isChatInitiating: false,
	fetchingChannels: false,
	userListedChannels: [],
	chatClientInfo: {
		twilioToken: '',
		chat: null,
	},
	channelsData: {},
	originalChannelsList: [],
	formattedChannelsList: [],
	eachChannelDetails: [],
	connectedChannel: {
		isConnected: false,
		isConnecting: false,
		fetchingMessages: false,
		activeChannel: {},
		messageDescriptors: {},
		messages: [],
		messageDetails: [],
		forScrolling: Math.random(),
		channelToBeLoaded: null,
	},
	markAllAsReadStatus: false,
	fetchedAllChannels: false,
	fetchNextSet: false,
	attachingMedia: false,
	chatAssets: {},
	changeMessageCount: 0,
};

function chatReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_CHANNEL_TO_BE_LOAD: {
			saveToLocalStorageAsJSONString('channelToBeLoad', action.channel);
			return state;
		}

		case REMOVE_CHANNEL_TO_BE_LOAD: {
			deleteFromLocalStorage('channelToBeLoad');
			return state;
		}
		case ADD_NEW_CHAT_PROFILE: {
			const { channelsData = {} } = state;
			const { items = [] } = channelsData;
			const { ChatChannel = [], newChannel } = action;

			const eachChannelDetails = {};
			ChatChannel.filter((channel) => !channel.isLocked).forEach((channel) => {
				const { memberSid, name, avatar, dob, gender } = channel;
				eachChannelDetails[channel.channelSid] = {
					memberSid,
					name,
					avatar,
					dob,
					gender,
				};
			});

			if (ChatChannel && ChatChannel.length > 0) {
				return {
					...state,
					userListedChannels: ChatChannel.filter((channel) => !channel.isLocked),
					eachChannelDetails,
					channelsData: {
						...state.channelsData,
						items: [newChannel].concat(items),
					},
				};
			}
			return {
				...state,
			};
		}
		case REMOVE_CHANNEL: {
			const { channelsData = {}, connectedChannel = {}, userListedChannels } = state;
			const { items = [] } = channelsData;
			const { activeChannel = {} } = connectedChannel;
			let isActive = false;
			if (
				activeChannel &&
				activeChannel.sid &&
				action &&
				action.memberInfo &&
				action.memberInfo.channel &&
				action.memberInfo.channel.sid
			) {
				isActive = activeChannel.sid === action.memberInfo.channel.sid;
			}

			delete state.eachChannelDetails[action.memberInfo.channel.sid];
			return {
				...state,
				channelsData: {
					...state.channelsData,
					items:
						(items && items.filter((channel) => channel.sid !== action.memberInfo.channel.sid)) ||
						[],
				},
				connectedChannel: {
					...state.connectedChannel,
					isConnected: false,
					activeChannel: isActive ? {} : state.connectedChannel.activeChannel,
					messageDescriptors: isActive ? {} : state.connectedChannel.messageDescriptors,
				},
				userListedChannels: userListedChannels.filter(
					(channel) => channel.channelSid !== action.memberInfo.channel.sid
				),
			};
		}
		case SAVE_MEDIA_ATTACHMENT_DATA: {
			const { chatAssets = {} } = state;
			const { activeChannelID, ...args } = action;
			const uniqueList = (chatAssets[activeChannelID] || []).filter(
				(x) => x.index !== action.index
			);
			const activeChannelAssets = [{ ...args }].concat(uniqueList);
			return {
				...state,
				chatAssets: {
					...state.chatAssets,
					[action.activeChannelID]: activeChannelAssets,
				},
			};
		}
		case MEDIA_ATTACHMENT: {
			return {
				...state,
				attachingMedia: action.attaching,
			};
		}
		case SAVE_USER_LISTED_CHANNELS: {
			const { chatChannels = [] } = action;
			const userListedChannels = chatChannels.filter((channel) => !channel.isLocked);
			const eachChannelDetails = {};
			userListedChannels.forEach((channel) => {
				const { memberSid, name, avatar, dob, gender } = channel;
				eachChannelDetails[channel.channelSid] = {
					...eachChannelDetails[channel.channelSid],
					memberSid,
					name,
					avatar,
					dob,
					gender,
				};
			});

			return {
				...state,
				userListedChannels,
				eachChannelDetails,
			};
		}
		case UPDATE_CHAT_PROFILE_NAMES: {
			const { userListedChannels = [] } = state;
			const { chatChannels = [] } = action;

			const newListWithUpdatedNames = userListedChannels.map((x) => {
				const record = chatChannels.find(
					(c) => c.channelSid === x.channelSid && c.userId === x.userId
				);

				if (record) {
					return {
						...x,
						name: record.name,
						avatar: record.avatar,
						avatarThumb: record.avatarThumb,
					};
				}

				return { ...x };
			});

			return {
				...state,
				userListedChannels: newListWithUpdatedNames,
			};
		}
		case FAILED_TO_FETCH_CHAT_TOKEN:
		case FETCH_TOKEN_FOR_INITIATING_CHAT:
			return {
				...state,
				isChatInitiating: action.fetching,
			};
		case SAVE_CHAT_TOKEN:
			return {
				...state,
				chatClientInfo: {
					...state.chatClientInfo,
					...action.data,
				},
				isChatInitiating: false,
			};
		case FETCH_CHANNELS: {
			return {
				...state,
				fetchingChannels: action.fetching,
			};
		}
		case SAVE_CHAT_OBJECT: {
			const { chat, channels = {} } = action;
			const { items = [] } = channels;
			const { userListedChannels = [] } = state;

			const activeChannelsItems = items.filter((channel) =>
				userListedChannels.find((list) => list.channelSid === channel.sid)
			);

			return {
				...state,
				chatClientInfo: {
					...state.chatClientInfo,
					chat,
				},
				channelsData: {
					...channels,
					items: sortByLastMessageArrived(activeChannelsItems),
				},
				fetchingChannels: false,
				fetchedAllChannels: true,
			};
		}
		case SAVE_NEW_CHANNELS_LIST: {
			const { channels = {} } = action;
			const { items = [] } = channels;
			const { userListedChannels = [], channelsData = {} } = state;
			const { items: presentChannels = [] } = channelsData;

			const activeChannelsItems = items.filter((channel) =>
				userListedChannels.find((list) => list.channelSid === channel.sid)
			);

			return {
				...state,
				channelsData: {
					...channels,
					items: presentChannels.concat(sortByLastMessageArrived(activeChannelsItems)),
				},
				fetchNextSet: false,
			};
		}
		case FETCH_NEW_CHANNELS_LIST:
			return {
				...state,
				fetchNextSet: action.isFetching,
			};
		case CHANNEL_MSG_UPDATED:
			return {
				...state,
				isChannelMessageUpdated: true,
			};
		case CHANNEL_READ_RECEIPT_UPDATED:
			return {
				...state,
				isReadReceiptUpdated: true,
			};
		case CONNECT_TO_A_CHANNEL:
			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					isConnecting: action.isConnecting,
					isConnected: action.isConnecting ? false : state.connectedChannel.isConnected,
				},
			};
		case SAVE_MESSAGE_DETAILS:
			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					isConnected: true,
					activeChannel: action.channel,
					messageDescriptors: action.messageObject,
					channelToBeLoaded: null,
				},
			};
		case SAVE_LAST_MESSAGE_ONLY: {
			const { Channel, Message } = action;
			const currentChannel = state.eachChannelDetails[Channel.sid];
			if (currentChannel) {
				currentChannel.lastMessage = Message;
				return {
					...state,
					eachChannelDetails: {
						...state.eachChannelDetails,
						[Channel.sid]: currentChannel,
					},
				};
			}

			return state;
		}
		case SAVE_LAST_MESSAGE_AND_REACHABILITY: {
			const { data = [] } = action.details;
			const eachChannelDetails = {};

			data.forEach((item) => {
				const { channel = {}, lastMessage = {}, userReachability = {} } = item;
				const currentChannel = state.eachChannelDetails[channel.sid];
				if (currentChannel) {
					currentChannel.lastMessage = lastMessage;
					if (userReachability) {
						currentChannel.isOnline = userReachability.isOnline;
						currentChannel.isNotifiable = userReachability.isNotifiable || false;
					}
					eachChannelDetails[item.sid] = { ...currentChannel };
				}
			});

			return {
				...state,
				eachChannelDetails: {
					...state.eachChannelDetails,
					...eachChannelDetails,
				},
			};
		}
		case MEMBER_TYPING: {
			const { member = {}, isTyping = false } = action;
			const channelId = member.conversation.sid;
			const currentChannel = state.eachChannelDetails[channelId];

			if (currentChannel) {
				currentChannel.isTyping = isTyping;
				return {
					...state,
					eachChannelDetails: {
						...state.eachChannelDetails,
						[channelId]: currentChannel,
					},
				};
			}
			return state;
		}
		case IS_USER_ONLINE: {
			const { channel = {}, isOnline = false, isNotifiable = false } = action;
			const channelId = channel.sid;
			const currentChannel = state.eachChannelDetails[channel.sid];

			if (currentChannel) {
				currentChannel.isOnline = isOnline;
				currentChannel.isNotifiable = isNotifiable;
				return {
					...state,
					eachChannelDetails: {
						...state.eachChannelDetails,
						[channelId]: currentChannel,
					},
				};
			}
			return state;
		}
		case SAVE_LAST_MESSAGE_DETAILS: {
			const { messageInfo = {} } = action;

			const channelId = (messageInfo.conversation && messageInfo.conversation.sid) || null;
			const currentChannel = channelId ? state.eachChannelDetails[channelId] : null;
			const { activeChannel = {}, messageDescriptors = {} } = state.connectedChannel;
			if (currentChannel) currentChannel.lastMessage = messageInfo;
			if (messageInfo.conversation.sid === activeChannel.sid) {
				return {
					...state,
					markAllAsReadStatus: false,
					// isChannelMessageUpdated:true,
					connectedChannel: {
						...state.connectedChannel,
						messageDescriptors: {
							...messageDescriptors,
							items: messageDescriptors.items.concat(messageInfo),
						},
						forScrolling: Math.random(),
					},
					eachChannelDetails: {
						...state.eachChannelDetails,
						[channelId]: currentChannel,
					},
					changeMessageCount: Math.random(),
				};
			}

			return {
				...state,
				markAllAsReadStatus: false,
				eachChannelDetails: {
					...state.eachChannelDetails,
					[channelId]: currentChannel,
				},
				changeMessageCount: Math.random(),
			};
		}
		case SAVE_MESSAGE_HISTORY: {
			const { messageInfo = {} } = action;
			const { items = [] } = messageInfo;
			const { messageDescriptors = {} } = state.connectedChannel;

			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					fetchingMessages: false,
					messageDescriptors: {
						...messageDescriptors,
						...messageInfo,
						items: items.concat(messageDescriptors.items),
					},
					forScrolling: Math.random(),
				},
			};
		}
		case FETCHING_MESSAGE_HISTORY:
			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					fetchingMessages: action.isFetching,
				},
			};
		case REARRANGE_CHANNELS: {
			const { channelsData: { items = [] } = {} } = state;
			return {
				...state,
				isChannelMessageUpdated: false,
				channelsData: {
					...state.channelsData,
					items: sortByLastMessageArrived(items),
				},
			};
		}
		case SORT_CHANNELS_BY_UNREAD_COUNT: {
			const { channelsData: { items = [] } = {} } = state;
			return {
				...state,
				channelsData: {
					...state.channelsData,
					items: getUnReadCount(items),
				},
			};
		}
		case MARK_ALL_AS_READ:
			return {
				...state,
				markAllAsReadStatus: true,
			};
		case CLEAR_ACTIVE_CHANNEL: {
			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					isConnected: false,
					isConnecting: false,
					fetchingMessages: false,
					activeChannel: {},
					messageDescriptors: {},
					messages: [],
					messageDetails: [],
					channelToBeLoaded: null,
				},
			};
		}
		case SAVE_CHANNEL_TO_BE_LOADED: {
			let channelToBeConnected = null;
			const user = state.userListedChannels.filter((user) => user.userId === action.userId);
			const { items: channels = [] } = state.channelsData;

			if (user && user[0]) {
				const channel = channels.filter((channel) => channel.sid === user[0].channelSid);
				channelToBeConnected = (channel && channel[0]) || null;
			}

			return {
				...state,
				connectedChannel: {
					...state.connectedChannel,
					channelToBeLoaded: channelToBeConnected,
				},
			};
		}

		default:
			return state;
	}
}

export default chatReducer;
