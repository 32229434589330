import { GET } from '../../service/HttpService';
import { vitalKeys, snackBarErrorTypes } from '../../helpers/Constants';

import { isUnauthorized } from '../Login/login.store';

import { showSnackbar } from '../../components/Snackbar/snackbar.store';

const FILE = 'FILE://HEALTH_VAULT';

const generateActionType = (type) => `${FILE}/${type}`;

const FETCH_PATIENT_DETAILS = generateActionType('FETCH_PATIENT_DETAILS');
const SAVE_PATIENT_DETAILS = generateActionType('SAVE_PATIENT_DETAILS');
const FETCH_VITALS_SUMMARY = generateActionType('FETCH_VITALS_SUMMARY');
const SAVE_VITALS_SUMMARY = generateActionType('SAVE_VITALS_SUMMARY');
const FETCH_RESPECTIVE_VITAL_INFO = generateActionType('FETCH_RESPECTIVE_VITAL_INFO');
const SAVE_RESPECTIVE_VITAL_INFO = generateActionType('SAVE_RESPECTIVE_VITAL_INFO');
const FETCH_CLINICAL_DOCS = generateActionType('FETCH_CLINICAL_DOCS');
const SAVE_CLINICAL_DOCS = generateActionType('SAVE_CLINICAL_DOCS');
const SAVE_RESPECTIVE_VITAL_GRAPH_DATA = generateActionType('SAVE_RESPECTIVE_VITAL_GRAPH_DATA');
const REMOVE_VITALS_INFO = generateActionType('REMOVE_VITALS_INFO');
const FETCHING_VAULT_CHART = generateActionType('FETCHING_VAULT_CHART');
const FETCHING_INSULIN_GRAPH_CHART = generateActionType('FETCHING_INSULIN_GRAPH_CHART');
const SAVE_VAULT_CHART = generateActionType('SAVE_VAULT_CHART');
const SAVE_INSULIN_GRAPH_CHART = generateActionType('SAVE_INSULIN_GRAPH_CHART');
const FETCH_VAULT_DETAILS = generateActionType('FETCH_VAULT_DETAILS');
const SAVE_VAULT_DETAILS = generateActionType('SAVE_VAULT_DETAILS');
const FETCHING_WEIGHTS = generateActionType('FETCHING_WEIGHTS');
const FETCHING_INSULIN = generateActionType('FETCHING_INSULIN');
const SAVE_WEIGHTS = generateActionType('SAVE_WEIGHTS');
const SAVE_INSULIN = generateActionType('SAVE_INSULIN');
const FETCH_ABNORMALITIES = generateActionType('FETCH_ABNORMALITIES');
const SAVE_ABNORMALITIES = generateActionType('SAVE_ABNORMALITIES');
const UPDATE_PATIENT_DIET_PLAN = generateActionType('UPDATE_PATIENT_DIET_PLAN');
const SAVE_UPDATED_PATIENT_DIET_PLAN = generateActionType('SAVE_UPDATED_PATIENT_DIET_PLAN');
const FETCH_MOODS = generateActionType('FETCH_MOODS');
const SAVE_MOODS = generateActionType('SAVE_MOODS');
const FETCH_SLEEP_ACTIVITIES = generateActionType('FETCH_SLEEP_ACTIVITIES');
const SAVE_SLEEP_ACTIVITIES = generateActionType('SAVE_SLEEP_ACTIVITIES');
const FETCH_LAB_TESTS_SUMMARY = generateActionType('FETCH_LAB_TESTS_SUMMARY');
const SAVE_LAB_TESTS_SUMMARY = generateActionType('SAVE_LAB_TESTS_SUMMARY');
const FETCH_LAB_REPORT_DETAILS = generateActionType('FETCH_LAB_REPORT_DETAILS');
const SAVE_FORMATTED_LAB_REPORT_DETAILS = generateActionType('SAVE_FORMATTED_LAB_REPORT_DETAILS');
const CLEAR_LAB_REPORT_DETAILS = generateActionType('CLEAR_LAB_REPORT_DETAILS');
const FETCH_LAB_REPORT_GRAPH_DETAILS = generateActionType('FETCH_LAB_REPORT_GRAPH_DETAILS');
const SAVE_LAB_REPORT_GRAPH_DETAILS = generateActionType('SAVE_LAB_REPORT_GRAPH_DETAILS');
const FETCH_ACTIVITY_JOURNAL = generateActionType('FETCH_ACTIVITY_JOURNAL');
const SAVE_ACTIVITY_JOURNAL = generateActionType('SAVE_ACTIVITY_JOURNAL');
const CHANGE_ACTIVITY_JOURNAL_SELECTED_DATE = generateActionType(
	'CHANGE_ACTIVITY_JOURNAL_SELECTED_DATE'
);

export function removeVitalsInfo(vital) {
	return {
		type: REMOVE_VITALS_INFO,
		vital,
	};
}

export function changingDietPlan(isUpdating) {
	return {
		type: UPDATE_PATIENT_DIET_PLAN,
		isUpdating,
	};
}

export function saveDietPlan(data) {
	return {
		type: SAVE_UPDATED_PATIENT_DIET_PLAN,
		data,
	};
}

export function clearLabDetails() {
	return {
		type: CLEAR_LAB_REPORT_DETAILS,
	};
}

export function changeSelectedDate({ selectedDate }) {
	return {
		type: CHANGE_ACTIVITY_JOURNAL_SELECTED_DATE,
		selectedDate,
	};
}

export async function getPatientDetails({ patientId }) {
	const patientConfig = { url: `patients/${patientId}` };
	const response = await GET(patientConfig);
	return response;
}

async function getVitalsSummary({ patientId }) {
	const vitalConfig = { url: `patients/${patientId}/vitals/summary` };
	const response = await GET(vitalConfig);
	return response;
}

async function getEachVitalInfo({ vital, patientId, params }) {
	const eachVitalConfig = { params, url: `patients/${patientId}/vitals/${vital}` };
	const response = await GET(eachVitalConfig);
	return response;
}

async function getClinicalDocs({ patientId, ...params }) {
	const eachVitalConfig = { params, url: `patients/${patientId}/clinical-documents` };
	const response = await GET(eachVitalConfig);
	return response;
}

async function getVaultDataForChart({ patientId, ...params }) {
	const chartConfig = { params, url: `patients/${patientId}/activityJournal` };
	const response = await GET(chartConfig);
	return response;
}

async function getRBSGraphData({ patientId, defaultView, ...params }) {
	const chartConfig = { params, url: `insulin-intakes/patients/${patientId}/intakes` };
	const response = await GET(chartConfig);

	return { [defaultView]: response.data };
}

async function getHealthVault({ patientId }) {
	const vaultConfig = { url: `patients/${patientId}/healthVault` };
	const response = await GET(vaultConfig);
	return response;
}

async function getWeightTracks({ patientId, ...params }) {
	const weightConfig = { params, url: `patients/${patientId}/weight` };
	const response = await GET(weightConfig);
	return response;
}

async function getAbnormalities({ patientId, params }) {
	const abnormalityConfig = { params, url: `patients/${patientId}/abnormalities` };
	const response = await GET(abnormalityConfig);
	return response;
}

async function getMoods({ patientId, params }) {
	const moodConfig = { params, url: `patients/${patientId}/mood-journal` };
	const response = await GET(moodConfig);
	return response;
}

async function getSleeps({ patientId, params }) {
	const sleepConfig = { params, url: `patients/${patientId}/sleep-journal` };
	const response = await GET(sleepConfig);
	return response;
}

async function labTestsSummary({ patientId }) {
	const labTestsSummaryConfig = { url: `patients/${patientId}/lab-reports/summary` };
	const response = await GET(labTestsSummaryConfig);
	return response;
}

export async function labTestDetails({ patientId, labTest, params }) {
	const labTestsSummaryConfig = {
		params,
		url: `patients/${patientId}/lab-reports/tests/${labTest}`,
	};
	const response = await GET(labTestsSummaryConfig);
	return response;
}

export async function availableLabTests() {
	const labTestsConfig = { url: `/lab-tests` };
	const response = await GET(labTestsConfig);
	return response;
}

export function getPatient(args) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_PATIENT_DETAILS, isFetching: true });
			const { data = {} } = await getPatientDetails(args);
			// let profilePic = '';
			// if (data.profile !== null && data.profile !== '') {
			// 	profilePic = await fileDownload(data.profile);
			// }
			dispatch({ type: SAVE_PATIENT_DETAILS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			const { status } = error.response || {};

			if (status === 403)
				dispatch(
					showSnackbar('The patient is no longer assigned to you', snackBarErrorTypes.error)
				);
			dispatch({ type: FETCH_PATIENT_DETAILS, isFetching: false });
			console.log(' Error in getPatient() ', error);
		}
	};
}

export function getPatientVitalsSummary(args) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_VITALS_SUMMARY, isFetching: true });
			const { data = {} } = await getVitalsSummary(args);
			dispatch({ type: SAVE_VITALS_SUMMARY, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_VITALS_SUMMARY, isFetching: false });
			console.log(' Error in getPatientVitalsSummary() ', error);
		}
	};
}

export function getEachVitalsSummary({ vital, ...rest }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_RESPECTIVE_VITAL_INFO, isFetching: true });
			const { data = {} } = await getEachVitalInfo({ vital, ...rest });
			dispatch({ type: SAVE_RESPECTIVE_VITAL_INFO, data, vital });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_RESPECTIVE_VITAL_INFO, isFetching: false });
			console.log(' Error in getEachVitalsSummary() ', error);
		}
	};
}

export function getPatientClinicalDocs(args) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_CLINICAL_DOCS, isFetching: true });
			const { data = {} } = await getClinicalDocs(args);
			dispatch({ type: SAVE_CLINICAL_DOCS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_CLINICAL_DOCS, isFetching: false });
			console.log(' Error in getPatientClinicalDocs() ', error);
		}
	};
}

export function getChartsData(args) {
	const {
		vitalName: vital,
		to,
		weekStart,
		monthStart,
		yearStart,
		patientId,
		graphsViews,
		defaultView,
	} = args;

	return async function (dispatch) {
		try {
			const [weekInfo = {}, monthInfo = {}, yearInfo = {}] = await Promise.all([
				getEachVitalInfo({ vital, patientId, params: { to, from: weekStart } }),
				getEachVitalInfo({ vital, patientId, params: { to, from: monthStart } }),
				getEachVitalInfo({ vital, patientId, params: { to, from: yearStart } }),
			]);
			const { data: weekData = {} } = weekInfo;
			const { data: monthData = {} } = monthInfo;
			const { data: yearData = {} } = yearInfo;
			dispatch({
				type: SAVE_RESPECTIVE_VITAL_GRAPH_DATA,
				defaultView,
				data: {
					[graphsViews.week]: {
						start: weekStart,
						end: to,
						data: weekData.data || [],
					},
					[graphsViews.month]: {
						start: monthStart,
						end: to,
						data: monthData.data || [],
					},
					[graphsViews.year]: {
						start: yearStart,
						end: to,
						data: yearData.data || [],
					},
				},
				vital,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			console.log(' Error in getChartsData() ', error);
		}
	};
}

export function getVaultGraph(args) {
	const { patientId, end, yearStart, monthStart, graphsViews } = args;
	return async function (dispatch) {
		try {
			const [year, month] = await Promise.all([
				await getVaultDataForChart({ patientId, from: yearStart, to: end }),
				await getVaultDataForChart({ patientId, from: monthStart, to: end }),
			]);
			dispatch({
				type: SAVE_VAULT_CHART,
				data: {
					[graphsViews.year]: {
						start: yearStart,
						end,
						data: year.data || [],
					},
					[graphsViews.month]: {
						start: monthStart,
						end,
						data: month.data || [],
					},
				},
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCHING_VAULT_CHART, isFetching: false });
			console.log(' Error in getVaultGraph() ', error);
		}
	};
}

export function getInsulinGraph(args) {
	const {
		patientId,
		end,
		weekStart,
		//  yearStart, monthStart,
		defaultView,
	} = args;

	console.log('DEFAULT------------VIWE', defaultView);
	return async function (dispatch) {
		try {
			dispatch({ type: FETCHING_INSULIN_GRAPH_CHART, isFetching: true });
			const [week] = await Promise.all([
				// await getRBSGraphData({ patientId, from: yearStart, to: end, defaultView }),
				// await getRBSGraphData({ patientId, from: monthStart, to: end, defaultView }),
				await getRBSGraphData({ patientId, fromDate: weekStart, toDate: end, defaultView }),
			]);
			dispatch({
				type: SAVE_INSULIN_GRAPH_CHART,
				data: {
					[defaultView]: {
						start: weekStart,
						end,
						data: week || [],
					},
				},
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCHING_INSULIN_GRAPH_CHART, isFetching: false });
			console.log(' Error in getVaultGraph() ', error);
		}
	};
}

export function getHealthVaultDetails(args) {
	return async function (dispatch) {
		try {
			const { data = {} } = await getHealthVault(args);
			dispatch({ type: SAVE_VAULT_DETAILS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_VAULT_DETAILS, isFetching: false });
			console.log(' Error in getHealthVaultDetails() ', error);
		}
	};
}

export function getWeightDetails(args) {
	return async function (dispatch) {
		try {
			const { patientId, end, start } = args;
			dispatch({ type: FETCHING_WEIGHTS, isFetching: true });
			const { data = {} } = await getWeightTracks({ patientId, from: start, to: end });
			dispatch({ type: SAVE_WEIGHTS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCHING_WEIGHTS, isFetching: false });
			console.log(' Error in getWeightDetails() ', error);
		}
	};
}

export async function getInsulinRBSAverageFBS(patientId = null) {
	const weightConfig = {
		url: `/insulin-intakes/summary`,
		params: {
			patientId,
			currentDate: `${new Date().getFullYear()}-${
				new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
			}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`,
		},
	};
	const response = await GET(weightConfig);
	return response.data;
}

export function getInsulinHistory(args) {
	return async function (dispatch) {
		try {
			const { patientId, end, start } = args;
			dispatch({ type: FETCHING_INSULIN, isFetching: true });
			const weightConfig = {
				params: { fromDate: start, toDate: end },
				url: `insulin-intakes/patients/${patientId}/intakes`,
			};

			console.log('INSULIN-------------DATA--- CALLED');
			const data = await GET(weightConfig);
			console.log('INSULIN-------------DATA', data);
			dispatch({ type: SAVE_INSULIN, data: data.data });
		} catch (error) {
			console.log(' Error in getWeightDetails() ', error);
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCHING_INSULIN, isFetching: false });
			console.log(' Error in getWeightDetails() ', error);
		}
	};
}

export async function getNormalInsulinRange(patientId) {
	const config = {
		url: `insulin-intakes/normal-range`,
		params: { patientId },
	};

	const response = await GET(config);
	return response;
}

export function getAbnormalityReport({ patientId, params }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_ABNORMALITIES, isFetching: true });
			const { data = {} } = await getAbnormalities({ patientId, params });
			dispatch({ type: SAVE_ABNORMALITIES, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_ABNORMALITIES, isFetching: false });
			console.log(' Error in getAbnormalityReport() ', error);
		}
	};
}

export function getMoodsList({ patientId, params }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_MOODS, isFetching: true });
			const { data = {} } = await getMoods({ patientId, params });
			dispatch({ type: SAVE_MOODS, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_MOODS, isFetching: false });
			console.log(' Error in getMoodsList() ', error);
		}
	};
}

export function getSleepActivities({ patientId, params }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_SLEEP_ACTIVITIES, isFetching: true });
			const { data = {} } = await getSleeps({ patientId, params });
			dispatch({ type: SAVE_SLEEP_ACTIVITIES, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_SLEEP_ACTIVITIES, isFetching: false });
			console.log(' Error in getSleepActivities() ', error);
		}
	};
}

export function getLabTestsReports({ patientId }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_LAB_TESTS_SUMMARY, isFetching: true });
			const [report, nextReport] = await Promise.all([
				availableLabTests({ patientId }),
				labTestsSummary({ patientId }),
			]);

			const { data: templates = [] } = report;
			const { data: summary = [] } = nextReport;

			dispatch({ type: SAVE_LAB_TESTS_SUMMARY, summary, templates });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_LAB_TESTS_SUMMARY, isFetching: false });
			console.log(' Error in getLabTestsReports() ', error);
		}
	};
}

function promiseCollection(args, callback) {
	return new Promise((resolve, reject) => {
		try {
			resolve(callback(args));
		} catch (error) {
			reject(error);
		}
	});
}

export function getLabTestDetails({ patientId, testSections, params }) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_LAB_REPORT_DETAILS, isFetching: true });
			const sections = (testSections || []).map((t) => t.lookupKey);
			const promise = sections.map((t) =>
				promiseCollection({ patientId, labTest: t, params }, labTestDetails)
			);
			const data = await Promise.all(promise);
			const obj = {};

			data.map((result, index) => {
				const { data = {} } = result;

				obj[sections[index]] = data.results || [];
				return result;
			});
			dispatch({ type: SAVE_FORMATTED_LAB_REPORT_DETAILS, data: obj, testSections });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_LAB_REPORT_DETAILS, isFetching: false });
			console.log(' Error in getLabTestDetails() ', error);
		}
	};
}

export function getGraphDetails(args) {
	const { labTest, end, weekStart, monthStart, yearStart, patientId, yearViewOnly, graphsViews } =
		args;
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_LAB_REPORT_GRAPH_DETAILS, fetching: true });
			if (yearViewOnly) {
				const { data: { results = [] } = {} } = await labTestDetails({
					patientId,
					labTest,
					params: { to: end, from: yearStart },
				});

				dispatch({
					type: SAVE_LAB_REPORT_GRAPH_DETAILS,
					data: {
						[graphsViews.year]: {
							start: yearStart,
							end,
							data: results || [],
						},
					},
				});
				dispatch({ type: FETCH_LAB_REPORT_GRAPH_DETAILS, fetching: false });

				return;
			}

			const [{ data: week = {} }, { data: month = {} }, { data: year = {} }] = await Promise.all([
				labTestDetails({
					patientId,
					labTest,
					params: { to: end, from: weekStart },
				}),
				labTestDetails({
					patientId,
					labTest,
					params: { to: end, from: monthStart },
				}),
				labTestDetails({
					patientId,
					labTest,
					params: { to: end, from: yearStart },
				}),
			]);

			dispatch({
				type: SAVE_LAB_REPORT_GRAPH_DETAILS,
				data: {
					[graphsViews.year]: {
						start: yearStart,
						end,
						data: year.results || [],
					},
					[graphsViews.month]: {
						start: monthStart,
						end,
						data: month.results || [],
					},
					[graphsViews.week]: {
						start: weekStart,
						end,
						data: week.results || [],
					},
				},
			});
			dispatch({ type: FETCH_LAB_REPORT_GRAPH_DETAILS, fetching: false });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_LAB_REPORT_GRAPH_DETAILS, fetching: false });
			console.log(' Error in getGraphDetails() ', error);
		}
	};
}

export function activityJournal(args) {
	return async function (dispatch) {
		try {
			dispatch({ type: FETCH_ACTIVITY_JOURNAL, fetching: true });
			const { data = [] } = await getVaultDataForChart(args);
			const { to, from } = args;

			dispatch({
				type: SAVE_ACTIVITY_JOURNAL,
				data: {
					to,
					from,
					data,
				},
				currentDate: to,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: FETCH_ACTIVITY_JOURNAL, fetching: false });
			console.log(' Error in activityJournal() ', error);
		}
	};
}

function getInitialState() {
	return {
		selectedPatient: {
			isFetching: true,
			details: {},
			isUpdating: false,
		},
		weightTracks: {
			isFetching: true,
			data: {},
		},
		insulinHistory: {
			isFetching: true,
			data: [],
		},
		abnormality: {
			isFetching: true,
			data: {},
		},
		healthVault: {
			chart: {
				drawing: true,
				data: {},
			},
			summary: {
				fetching: true,
				data: {},
			},
		},
		insulinGraph: {
			chart: {
				drawing: true,
				data: {},
			},
			summary: {
				fetching: true,
				data: {},
			},
		},
		vitals: {
			isFetching: true,
			summary: [],
			info: {
				isFetching: true,
				[vitalKeys.systolic]: { graph: { loading: true, data: {} } },
				[vitalKeys.temperature]: { graph: { loading: true, data: {} } },
				[vitalKeys.heartRate]: { graph: { loading: true, data: {} } },
				[vitalKeys.respiratoryRate]: { graph: { loading: true, data: {} } },
				[vitalKeys.oxygenSaturation]: { graph: { loading: true, data: {} } },
			},
		},
		clinicalDocs: {
			isFetching: true,
			details: {},
		},
		moods: {
			fetching: true,
			details: {},
		},
		sleepTracks: {
			fetching: true,
			details: {},
		},
		labTests: {
			fetching: true,
			labReportsList: [],
			totalReportTemplates: [],
			labReportSection: {
				fetching: true,
				details: {},
				keys: {},
			},
			labReportGraph: {
				fetching: true,
				details: {},
			},
		},
		activityJournal: {
			fetching: true,
			selectedDate: '',
			details: {},
		},
	};
}

function reducer(state = getInitialState(), action) {
	switch (action.type) {
		case CHANGE_ACTIVITY_JOURNAL_SELECTED_DATE:
			return {
				...state,
				activityJournal: {
					...state.activityJournal,
					selectedDate: action.selectedDate,
				},
			};
		case FETCH_ACTIVITY_JOURNAL:
			return {
				...state,
				activityJournal: {
					...state.activityJournal,
					fetching: action.fetching,
				},
			};
		case SAVE_ACTIVITY_JOURNAL:
			return {
				...state,
				activityJournal: {
					...state.activityJournal,
					details: action.data || {},
					fetching: false,
					selectedDate: action.currentDate,
				},
			};
		case FETCH_LAB_REPORT_GRAPH_DETAILS:
			return {
				...state,
				labTests: {
					...state.labTests,
					labReportGraph: {
						...state.labTests.labReportGraph,
						fetching: action.fetching,
					},
				},
			};
		case SAVE_LAB_REPORT_GRAPH_DETAILS:
			return {
				...state,
				labTests: {
					...state.labTests,
					labReportGraph: {
						...state.labTests.labReportGraph,
						// fetching: false,
						details: {
							...state.labTests.labReportGraph.details,
							...action.data,
						},
					},
				},
			};
		case CLEAR_LAB_REPORT_DETAILS:
			return {
				...state,
				labTests: {
					...state.labTests,
					fetching: true,
					labReportSection: {
						fetching: true,
						details: {},
					},
				},
			};
		case FETCH_LAB_REPORT_DETAILS: {
			return {
				...state,
				labTests: {
					...state.labTests,
					labReportSection: {
						...state.labTests.labReportSection,
						fetching: action.isFetching,
					},
				},
			};
		}
		case SAVE_FORMATTED_LAB_REPORT_DETAILS: {
			return {
				...state,
				labTests: {
					...state.labTests,
					labReportSection: {
						...state.labTests.labReportSection,
						details: action.data,
						fetching: false,
						keys: action.testSections || [],
					},
				},
			};
		}
		case FETCH_LAB_TESTS_SUMMARY:
			return {
				...state,
				labTests: {
					...state.labTests,
					fetching: action.isFetching,
				},
			};
		case SAVE_LAB_TESTS_SUMMARY:
			return {
				...state,
				labTests: {
					...state.labTests,
					fetching: false,
					labReportsList: action.summary || [],
					totalReportTemplates: action.templates || [],
				},
			};
		case FETCH_SLEEP_ACTIVITIES:
			return {
				...state,
				sleepTracks: {
					...state.sleepTracks,
					fetching: action.isFetching,
				},
			};
		case SAVE_SLEEP_ACTIVITIES:
			return {
				...state,
				sleepTracks: {
					...state.sleepTracks,
					details: action.data,
					fetching: false,
				},
			};
		case FETCH_MOODS:
			return {
				...state,
				moods: {
					...state.moods,
					fetching: action.isFetching,
				},
			};
		case SAVE_MOODS:
			return {
				...state,
				moods: {
					...state.moods,
					details: action.data,
					fetching: false,
				},
			};
		case UPDATE_PATIENT_DIET_PLAN:
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					isUpdating: action.isUpdating,
				},
			};
		case SAVE_UPDATED_PATIENT_DIET_PLAN:
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					isUpdating: false,
					details: action.data,
				},
			};
		case FETCH_PATIENT_DETAILS:
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					isFetching: action.isFetching,
				},
			};
		case SAVE_PATIENT_DETAILS:
			return {
				...state,
				selectedPatient: {
					...state.selectedPatient,
					isFetching: false,
					details: action.data,
				},
			};
		case FETCH_VITALS_SUMMARY:
			return {
				...state,
				vitals: {
					...state.vitals,
					isFetching: action.isFetching,
				},
			};
		case SAVE_VITALS_SUMMARY:
			return {
				...state,
				vitals: {
					...state.vitals,
					isFetching: false,
					summary: action.data || [],
				},
			};
		case FETCH_RESPECTIVE_VITAL_INFO:
			return {
				...state,
				vitals: {
					...state.vitals,
					info: {
						...state.vitals.info,
						isFetching: action.isFetching,
					},
				},
			};
		case SAVE_RESPECTIVE_VITAL_INFO:
			return {
				...state,
				vitals: {
					...state.vitals,
					info: {
						...state.vitals.info,
						isFetching: false,
						[action.vital]: {
							...state.vitals.info[action.vital],
							...action.data,
						},
					},
				},
			};
		case FETCH_CLINICAL_DOCS:
			return {
				...state,
				clinicalDocs: {
					...state.clinicalDocs,
					isFetching: action.isFetching,
				},
			};
		case SAVE_CLINICAL_DOCS:
			return {
				...state,
				clinicalDocs: {
					...state.clinicalDocs,
					details: action.data,
					isFetching: false,
				},
			};
		case SAVE_RESPECTIVE_VITAL_GRAPH_DATA:
			return {
				...state,
				vitals: {
					...state.vitals,
					info: {
						...state.vitals.info,
						[action.vital]: {
							...state.vitals.info[action.vital],
							data: action.data[action.defaultView].data,

							graph: { loading: false, data: { ...action.data } },
						},
						isFetching: false,
					},
				},
			};
		case REMOVE_VITALS_INFO:
			return {
				...state,
				vitals: {
					...state.vitals,
					info: {
						...state.vitals.info,
						isFetching: true,
						[action.vital]: {
							graph: { loading: true, data: {} },
						},
					},
				},
			};
		case FETCHING_VAULT_CHART:
			return {
				...state,
				healthVault: {
					...state.healthVault,
					chart: {
						...state.healthVault.chart,
						drawing: action.isFetching,
					},
				},
			};
		case FETCHING_INSULIN_GRAPH_CHART:
			return {
				...state,
				insulinGraph: {
					...state.insulinGraph,
					chart: {
						...state.insulinGraph.chart,
						drawing: action.isFetching,
					},
				},
			};

		case SAVE_VAULT_CHART:
			return {
				...state,
				healthVault: {
					...state.healthVault,
					chart: {
						drawing: false,
						data: action.data,
					},
				},
			};
		case SAVE_INSULIN_GRAPH_CHART:
			return {
				...state,
				insulinGraph: {
					...state.insulinGraph,
					chart: {
						drawing: false,
						data: action.data.week.data,
					},
				},
			};
		case FETCH_VAULT_DETAILS:
			return {
				...state,
				healthVault: {
					...state.healthVault,
					summary: {
						fetching: action.isFetching,
						data: {},
					},
				},
			};
		case SAVE_VAULT_DETAILS:
			return {
				...state,
				healthVault: {
					...state.healthVault,
					summary: {
						fetching: false,
						data: action.data,
					},
				},
			};
		case FETCHING_WEIGHTS:
			return {
				...state,
				weightTracks: {
					isFetching: action.isFetching,
					data: {},
				},
			};
		case FETCHING_INSULIN:
			return {
				...state,
				insulinHistory: {
					isFetching: action.isFetching,
					data: {},
				},
			};
		case SAVE_WEIGHTS:
			return {
				...state,
				weightTracks: {
					data: action.data,
					isFetching: false,
				},
			};
		case SAVE_INSULIN:
			return {
				...state,
				insulinHistory: {
					data: action.data,
					isFetching: false,
				},
			};
		case FETCH_ABNORMALITIES:
			return {
				...state,
				abnormality: {
					isFetching: action.isFetching,
					data: {},
				},
			};
		case SAVE_ABNORMALITIES:
			return {
				...state,
				abnormality: {
					isFetching: false,
					data: action.data,
				},
			};
		default:
			return state;
	}
}

export default reducer;
