import { DELETE, GET, POST } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
import { PER_PAGE } from '../../helpers/Constants';

import { isUnauthorized } from '../Login/login.store';
import { MixPanelService } from '../../service/MixpanelService';

const FILE = 'FILE://PATIENT_INVITATION';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_LIST = generateActionType('GET_LIST');
const GET_LIST_PAGINATION = generateActionType('GET_LIST_PAGINATION');
const BEFORE_LIST = generateActionType('BEFORE_LIST');
const STORE_KEYWORDS = generateActionType('STORE_KEYWORDS');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_STATUS = generateActionType('CLEAR_STATUS');

// Reducer
const initialState = {
	initialList: true,
	fetching: false,
	dataLoader: true,
	detailsFetching: false,
	list: [],
	listOffset: 0,
	keywords: '',
	statusCode: 0,
	noOfResults: 0,
};

export function searchKeyword(searchKeywords = '') {
	return function (dispatch) {
		dispatch({ type: STORE_KEYWORDS, keyword: searchKeywords });
	};
}

export function clearStatus() {
	return function (dispatch) {
		dispatch({ type: CLEAR_STATUS });
	};
}

export function getInviteesListAction(data = {}, headers = {}) {
	const {
		limit = PER_PAGE,
		offset = 0,
		pagination = false,
		searchKeyword = '',
		countryCode = '',
		initialList = true,
		status = 'pending',
	} = data;

	const initialLists = initialList;

	const getConfig = {
		url: `invitees`,
		params:
			countryCode !== ''
				? { limit, offset, status, q: searchKeyword, countryCode }
				: { limit, offset, status, q: searchKeyword },
		headers,
	};
	return async function (dispatch) {
		try {
			dispatch({ type: BEFORE_LIST, dataLoader: true });
			dispatch({ type: STORE_KEYWORDS, searchKeyword });
			// initialLists = !pagination;
			const type = pagination ? GET_LIST_PAGINATION : GET_LIST;

			const { data = {} } = await GET(getConfig);
			const { results = [], noOfResults = 0, totalResults = 0 } = data;
			dispatch({
				type,
				data: results,
				noOfResults,
				totalResults,
				offset,
				initialList: initialLists,
				dataLoader: false,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch({
				type: GET_LIST,
				data: [],
				listOffset: 0,
				initialList: true,
				dataLoader: false,
			});
			dispatch(errorHandling(error));
		}
	};
}

export async function deleteInvitee(inviteeId) {
	const deleteConfig = { url: `/invite/${inviteeId}` };

	const response = await DELETE(deleteConfig);
	return response;
}

export function sendInvitation(datas = {}, headers = {}) {
	const postConfig = { data: datas, url: `invite`, headers };
	if (datas.prefix.length === 0) {
		delete postConfig.data.prefix;
	}
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			MixPanelService.track('send_patient_invite', { datas });
			dispatch(getInviteesListAction({}, headers));
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Invitation sent successfully', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function reSendInviteAction(datas = {}, headers = {}) {
	const postConfig = { data: datas, url: `accounts/resendInvite`, headers };
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			// dispatch(getInviteesListAction());
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Invitation resent successfully', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

function patientInvitationReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case STORE_KEYWORDS:
			return {
				...state,
				keywords: action.searchKeyword,
			};
		case BEFORE_LIST:
			return {
				...state,
				initialList: false,
				dataLoader: true,
			};
		case GET_LIST: {
			return {
				...state,
				list: action.data,
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: 0,
				noOfResults: 0,
				totalResults: action.totalResults,
			};
		}
		case GET_LIST_PAGINATION: {
			const { data } = action;
			return {
				...state,
				list: [...state.list, ...data],
				initialList: false,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: action.offset,
				noOfResults: action.noOfResults,
				totalResults: action.totalResults,
			};
		}
		case CLEAR_STATUS:
			return {
				...state,
				statusCode: 0,
			};
		default:
			return state;
	}
}

export default patientInvitationReducer;
