/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWebNotifications } from '../../dashboard.selectors';
import { getNotifications, resetReadStatus } from '../../../Login/login.store';

import Notifications from '.';
import bellIcon from '../../../../stories/assets/images/icons/bell-icon.svg';

function BellIcon() {
	const dispatch = useDispatch();
	const [notificationView, toggleNotificationView] = useState(false);

	const { data: { unreadCount = 0 } = {} } = useSelector(({ AuthStore }) =>
		getWebNotifications(AuthStore)
	);

	function closeNotification(value) {
		if (typeof value === 'boolean') {
			toggleNotificationView(value);
		}

		toggleNotificationView((previousState) => !previousState);
	}

	function resetCount() {
		if (unreadCount > 0) {
			dispatch(getNotifications({ params: { markRead: true } }, true));
		}

		closeNotification();
	}

	function markAllAsRead() {
		dispatch(resetReadStatus());
	}

	return (
		<div role="button" style={{ position: 'relative', margin: '0 25px' }} onClick={resetCount}>
			<img src={bellIcon} alt="bellIcon" style={{ cursor: 'pointer' }} />
			{unreadCount > 0 && (
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						borderRadius: '50%',
						height: '9px',
						width: '9px',
						background: '#E86764',
					}}
				/>
			)}
			{notificationView && (
				<Notifications
					closeNotification={(val) => closeNotification(val)}
					markAllAsRead={() => markAllAsRead()}
				/>
			)}
		</div>
	);
}

export default BellIcon;
