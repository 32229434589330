import styled from 'styled-components';
import { fontBold, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const BredcrumbListItem = styled.li`
	margin: 0px;
	list-style: none;
	padding: 0px;
	display: inline-block;
	font-size: 12px;
	line-height: 14px;
	color: ${colors.BlueBase};
	position: relative;
	font-family: ${fontBold.familyName};
	padding-right: 20px;
	:after {
		content: '\\e904';
		position: absolute;
		right: 4px;
		top: 0.5px;
		color: ${colors.Grey8};
		font-size: 10px;
		font-family: 'iconFont' !important;
	}
	a {
		font-family: ${fontBold.familyName};
		color: ${colors.BlueBase};
		font-size: 12px;
		text-decoration: none;
		cursor: pointer;
		line-height: 14px;
		transition: ease-in all 0.4s;
		:hover {
			color: ${colors.BlueDark};
		}
	}
	${({ active }) =>
		active &&
		`:after{ display: none; }
			font-family:${fontRegular.familyName}
			color: ${colors.GreyA2};
			:last-child{
				:after{ display: none; }
			}
	`}
`;
export default BredcrumbListItem;
