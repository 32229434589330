import firebase from 'firebase';

// import 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyCX-pUFFzjpitFr-f3sXmlRI3VdRGwOqms',
	authDomain: 'wecare-hh.firebaseapp.com',
	databaseURL: 'https://wecare-hh.firebaseio.com',
	projectId: 'wecare-hh',
	storageBucket: 'wecare-hh.appspot.com',
	messagingSenderId: '608025390755',
	appId: '1:608025390755:web:f20f8a9e74ac6b41599916',
	measurementId: 'G-003PVCZCHY',
};

firebase.initializeApp(firebaseConfig);

const fcm = {
	isSupported: firebase.messaging.isSupported(),
};

if (fcm.isSupported) {
	fcm.messaging = firebase.messaging();
	// fcm.messaging.usePublicVapidKey(
	// 	'BOdS0ZFXrYsU3OkqFQdLFZ-I8g62tHxNbvwGIN6pkWocj0E0e_TXU9Eyk2LakWn3Qo913VBgMFnJ9f1jceO5IXY'
	// );
}

export default fcm;
