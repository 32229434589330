import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import AppRoutes from './Routes';
import SnackBarToast from './components/Snackbar/SnackBar';
import NetworkStatusBar from './components/Offline/NetworkStatusBar';

import { AppStore } from './store';

import GlobalStyle from './stories/assets/styles/globalStyle';

function App() {
	return (
		<Sentry.ErrorBoundary fallback="An error has occurred. This error message is a fallback message from Sentry.ErrorBoundary inside App.js">
			<Provider store={AppStore}>
				<NetworkStatusBar />
				<SnackBarToast />
				<AppRoutes />
				<GlobalStyle />
			</Provider>
		</Sentry.ErrorBoundary>
	);
}

export default App;
