import { GET, POST, PUT } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
import { PER_PAGE } from '../../helpers/Constants';

const FILE = 'FILE://CLINICAL_NOTES';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const INITIALIZE_NOTES = generateActionType('INITIALIZE_NOTES');
const RETURN_CLINICAL_NOTES = generateActionType('RETURN_CLINICAL_NOTES');
const INITIALIZE_NOTES_PAGINATION = generateActionType('INITIALIZE_NOTES_PAGINATION');
const GET_PATIENT_DETAILS = generateActionType('GET_PATIENT_DETAILS');
const GET_PATIENT_ID = generateActionType('GET_PATIENT_ID');
const GET_VITALS_RECORD = generateActionType('GET_VITALS_RECORD');
const GET_CLINICAL_NOTES = generateActionType('GET_CLINICAL_NOTES');
const GET_CLINICAL_NOTES_PAGINATION = generateActionType('GET_CLINICAL_NOTES_PAGINATION');
const SAVE_VITALS_RECORD = generateActionType('SAVE_VITALS_RECORD');
const SAVE_CLINICAL_NOTES = generateActionType('SAVE_CLINICAL_NOTES');
const EDIT_CLINICAL_NOTES = generateActionType('EDIT_CLINICAL_NOTES');
const GET_TOAST = generateActionType('GET_TOAST');
const CLEAR_SAVED_NOTES = generateActionType('CLEAR_SAVED_NOTES');
const CLEAN_CLINICAL_NOTES = generateActionType('CLEAN_CLINICAL_NOTES');
const CLEAR_VALIDATION = generateActionType('CLEAR_VALIDATION');
const FAILED_CLINICAL_NOTES = generateActionType('FAILED_CLINICAL_NOTES');
const GET_ABNORMALITY_REPORTS = generateActionType('GET_ABNORMALITY_REPORTS');
const SAVE_ABNORMALITY_REPORT = generateActionType('SAVE_ABNORMALITY_REPORT');
const GET_LABTEST_REPORTS = generateActionType('GET_LABTEST_REPORTS');
const SAVE_LABTEST_REPORT = generateActionType('SAVE_LABTEST_REPORT');
const DRAFT_NOTES = generateActionType('DRAFT_NOTES');

// Reducer
const initialState = {
	patientId: 0,
	fetching: false,
	isEdit: false,
	statusCode: 0,
	totalResults: 0,
	patientDetails: {},
	healthRecord: {},
	vitalsAllReports: [],
	clinicalNotesShow: true,
	clinicalNotes: {
		coach: [],
		doctor: [],
	},
	labTest: {
		allReports: [],
		totalResults: 0,
		offset: 0,
	},
	abnormalityAllReport: {
		allReports: [],
		totalResults: 0,
		offset: 0,
	},
	vitalsAllReport: {
		allReports: [],
		totalResults: 0,
		offset: 0,
	},
	coachTotalRecord: 0,
	doctorTotalRecord: 0,

	coachLastRecord: 0,
	doctorLastRecord: 0,

	coachOffset: 0,
	doctorOffset: 0,

	coachNotesLoader: false,
	doctorNotesLoader: false,

	coachNotesInitialFetch: false,
	doctorNotesInitialFetch: false,

	noOfResults: 0,
	saveHealthRecord: [],
	saveClinicalNotes: {},
	draftClinicalNotes: {},
	isMessage: false,
	messageType: '',
	messageContent: {},
	error: {},
};

export function clearValidation() {
	return function (dispatch) {
		dispatch({ type: CLEAR_VALIDATION });
	};
}

export function returnClinicalNotes(data) {
	return function (dispatch) {
		dispatch({ type: RETURN_CLINICAL_NOTES, provider: data });
	};
}

export function PatientDetailsAction(id = 0) {
	const patientConfig = { url: `patients/${id}` };
	const fetching = true;
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(patientConfig);
			dispatch({ type: GET_PATIENT_ID, id });
			dispatch({ type: GET_PATIENT_DETAILS, data, fetching });
		} catch (error) {
			dispatch({ type: GET_PATIENT_DETAILS, data: {}, fetching });
			dispatch(errorHandling(error));
		}
	};
}

export function GetVitalReportsAction(data = {}) {
	const { id = 0, limit = PER_PAGE, offset = 0 /* , pagination = false */ } = data;
	const getConfig = { url: `/patients/${id}/vitals?limit=${limit}&offset=${offset}` };
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_VITALS_RECORD, data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function VitalReportsSave(data = []) {
	return async function (dispatch) {
		try {
			dispatch({ type: SAVE_VITALS_RECORD, inputsVitals: data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function AbnormalityReportsAction(data = {}) {
	const { id = 0, limit = PER_PAGE, offset = 0 /* , pagination = false */ } = data;
	const getConfig = { url: `/patients/${id}/abnormalities?limit=${limit}&offset=${offset}` };
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			console.log('i got here', data);
			dispatch({ type: GET_ABNORMALITY_REPORTS, data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function AbnormalityReportsSave(data = []) {
	return async function (dispatch) {
		try {
			dispatch({ type: SAVE_ABNORMALITY_REPORT, inputsAbnormality: data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function GetLabTestsAction(data = {}) {
	const { id = 0, limit = PER_PAGE, offset = 0 /* , pagination = false */ } = data;
	const getConfig = { url: `/patients/${id}/lab-reports?limit=${limit}&offset=${offset}` };
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_LABTEST_REPORTS, data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function LabTestSave(data = []) {
	return async function (dispatch) {
		try {
			dispatch({ type: SAVE_LABTEST_REPORT, inputsLabTest: data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function ClinicalNotesAction(data = {}) {
	const {
		id = 0,
		provider = '',
		roles = '',
		limit = PER_PAGE,
		offset = 0,
		pagination = false,
	} = data;
	// const getConfig = {
	// 	url: `/patients/${id}/clinical-notes?limit=${limit}&offset=${offset}&provider=${provider}`,
	// };
	const getConfig = {
		url: `/patients/${id}/clinical-notes?limit=${limit}&offset=${offset}`,
	};
	return async function (dispatch) {
		try {
			if (pagination) {
				dispatch({ type: INITIALIZE_NOTES_PAGINATION, provider });
			} else {
				dispatch({ type: INITIALIZE_NOTES, provider });
			}

			const type = pagination ? GET_CLINICAL_NOTES_PAGINATION : GET_CLINICAL_NOTES;
			const { data = {} } = await GET(getConfig);
			const { results = [], noOfResults = 0 } = data;
			let { totalResults = 0 } = data;
			let inputsNotes = {};
			let inputsVitals = [];
			let inputsAbnormality = [];
			let inputsLabTest = [];
			// filter data only for notes
			const filterResults = results.filter((r) => {
				const {
					isDraft = false,
					notes = [],
					vitalReports = [],
					abnormalityReports = [],
					labReportDetails = [],
					noteId = 0,
					providerType = '',
				} = r;
				totalResults = isDraft ? totalResults - 1 : totalResults;
				if (isDraft && provider === roles) {
					if (notes.length !== 0) {
						notes.forEach((n) => {
							const { note = '', type = '' } = n;
							inputsNotes = { ...inputsNotes, [type]: note };
						});
					}

					if (Array.isArray(vitalReports) && vitalReports.length !== 0) {
						vitalReports.forEach((h) => {
							const { vitalReportId = 0 } = h;
							inputsVitals = [...inputsVitals, `${vitalReportId}`];
						});
					}
					if (Array.isArray(abnormalityReports) && abnormalityReports.length !== 0) {
						abnormalityReports.forEach((h) => {
							const { id = 0 } = h;
							inputsAbnormality = [...inputsAbnormality, `${id}`];
						});
					}
					if (Array.isArray(labReportDetails) && labReportDetails.length !== 0) {
						labReportDetails.forEach((h) => {
							const { id = 0 } = h;
							inputsLabTest = [...inputsLabTest, `${id}`];
						});
					}

					dispatch({ type: EDIT_CLINICAL_NOTES, id: noteId });
					dispatch({ type: SAVE_CLINICAL_NOTES, inputsNotes });
					dispatch({ type: SAVE_VITALS_RECORD, inputsVitals });
					dispatch({ type: SAVE_ABNORMALITY_REPORT, inputsAbnormality });
					dispatch({ type: SAVE_LABTEST_REPORT, inputsLabTest });
					dispatch({ type: DRAFT_NOTES, data: r });
				}
				return isDraft === false && providerType === provider;
			});

			dispatch({
				type,
				results: filterResults,
				provider,
				totalResults,
				noOfResults,
				offset,
			});
		} catch (error) {
			dispatch({ type: FAILED_CLINICAL_NOTES });
			dispatch(errorHandling(error));
		}
	};
}

export function ClinicalNotesSaveAction(data = {}) {
	return async function (dispatch) {
		try {
			dispatch({ type: SAVE_CLINICAL_NOTES, inputsNotes: data });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function ClinicalNotesEditAction(id = 0) {
	return async function (dispatch) {
		try {
			dispatch({ type: EDIT_CLINICAL_NOTES, id });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function ClinicalNotesSendAction(
	id = 0,
	datas = {},
	currentUserRole = '',
	isEdit = true,
	noteId = 0
) {
	const postConfig = { data: datas, url: `/patients/${id}/clinical-notes` };
	const putConfig = { data: datas, url: `/patients/${id}/clinical-notes/${noteId}` };
	return async function (dispatch) {
		try {
			const { status = '' } = isEdit ? await PUT(putConfig) : await POST(postConfig);

			if (status === 200) {
				dispatch({ type: CLEAR_SAVED_NOTES });
				dispatch(ClinicalNotesAction({ id, provider: currentUserRole, roles: currentUserRole }));
			}
			const messages = isEdit ? 'updated' : 'added';
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: `Record successfully ${messages}`, key: '' } },
				})
			);
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

export function CleanClinicalNotes() {
	return async function (dispatch) {
		try {
			dispatch({ type: CLEAN_CLINICAL_NOTES });
		} catch (error) {
			dispatch(errorHandling(error));
		}
	};
}

function clinicalNotesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_TOAST: {
			return {
				...state,
				isMessage: action.isMessage,
				messageType: action.messageType,
				messageContent: action.messageContent,
			};
		}
		case GET_PATIENT_DETAILS:
			return {
				...state,
				patientDetails: action.data,
				fetching: action.fetching,
			};

		case GET_PATIENT_ID:
			return {
				...state,
				patientId: action.id,
			};
		case GET_VITALS_RECORD: {
			const { results = [], totalResults = 0, offset = 0 } = action.data;
			return {
				...state,
				vitalsAllReport: {
					allReports: [...state.vitalsAllReport.allReports, ...results],
					totalResults,
					offset,
				},
			};
		}
		case GET_ABNORMALITY_REPORTS: {
			const { results = [], totalResults = 0, offset = 0 } = action.data;
			return {
				...state,
				abnormalityAllReport: {
					allReports: [...state.abnormalityAllReport.allReports, ...results],
					totalResults,
					offset,
				},
			};
		}
		case GET_LABTEST_REPORTS: {
			const { results = [], totalResults = 0, offset = 0 } = action.data;
			return {
				...state,
				labTest: {
					allReports: [...state.labTest.allReports, ...results],
					totalResults,
					offset,
				},
			};
		}
		case INITIALIZE_NOTES: {
			const { provider } = action;
			return {
				...state,
				coachNotesInitialFetch: provider === 'coach' ? true : state.coachNotesInitialFetch,
				doctorNotesInitialFetch: provider === 'doctor' ? true : state.doctorNotesInitialFetch,
			};
		}
		case RETURN_CLINICAL_NOTES: {
			const { provider } = action;
			return {
				...state,
				coachNotesInitialFetch: provider === 'coach' ? true : state.coachNotesInitialFetch,
				doctorNotesInitialFetch: provider === 'doctor' ? true : state.doctorNotesInitialFetch,
				noteFetching: false,
			};
		}
		case INITIALIZE_NOTES_PAGINATION: {
			const { provider } = action;
			return {
				...state,
				coachNotesLoader: provider === 'coach' ? true : state.coachNotesLoader,
				doctorNotesLoader: provider === 'doctor' ? true : state.doctorNotesLoader,
			};
		}
		case GET_CLINICAL_NOTES: {
			const { provider, results, totalResults, noOfResults, offset } = action;
			state.clinicalNotes[provider] = [];
			return {
				...state,
				...state.clinicalNotes[provider].push(...results),
				coachTotalRecord: provider === 'coach' ? totalResults : state.coachTotalRecord,
				doctorTotalRecord: provider === 'doctor' ? totalResults : state.doctorTotalRecord,
				coachLastRecord: provider === 'coach' ? noOfResults : state.coachLastRecord,
				doctorLastRecord: provider === 'doctor' ? noOfResults : state.doctorLastRecord,

				coachOffset: provider === 'coach' ? offset : state.coachOffset,
				doctorOffset: provider === 'doctor' ? offset : state.doctorOffset,

				coachNotesLoader: provider === 'coach' ? false : state.coachNotesLoader,
				doctorNotesLoader: provider === 'doctor' ? false : state.doctorNotesLoader,
				coachNotesInitialFetch: provider === 'coach' ? false : state.coachNotesInitialFetch,
				doctorNotesInitialFetch: provider === 'doctor' ? false : state.doctorNotesInitialFetch,
				clinicalNotesShow: true,
				noteFetching: true,
			};
		}
		case GET_CLINICAL_NOTES_PAGINATION: {
			const { provider, results, totalResults, noOfResults, offset } = action;
			const notes = state.clinicalNotes[provider];
			return {
				...state,
				...notes.push(...results),
				coachTotalRecord: provider === 'coach' ? totalResults : state.coachTotalRecord,
				doctorTotalRecord: provider === 'doctor' ? totalResults : state.doctorTotalRecord,
				coachLastRecord: provider === 'coach' ? noOfResults : state.coachLastRecord,
				doctorLastRecord: provider === 'doctor' ? noOfResults : state.doctorLastRecord,

				coachOffset: provider === 'coach' ? offset : state.coachOffset,
				doctorOffset: provider === 'doctor' ? offset : state.doctorOffset,

				coachNotesLoader: provider === 'coach' ? false : state.coachNotesLoader,
				doctorNotesLoader: provider === 'doctor' ? false : state.doctorNotesLoader,
				clinicalNotesShow: true,
			};
		}
		case SAVE_VITALS_RECORD:
			state.saveHealthRecord.vitalReports = [];
			return {
				...state,
				...state.saveHealthRecord.vitalReports.push(...action.inputsVitals),
			};
		case SAVE_ABNORMALITY_REPORT:
			state.saveHealthRecord.abnormalityReports = [];
			return {
				...state,
				...state.saveHealthRecord.abnormalityReports.push(...action.inputsAbnormality),
			};
		case SAVE_LABTEST_REPORT:
			state.saveHealthRecord.labReports = [];
			return {
				...state,
				...state.saveHealthRecord.labReports.push(...action.inputsLabTest),
			};
		case SAVE_CLINICAL_NOTES:
			return {
				...state,
				saveClinicalNotes: action.inputsNotes,
			};
		case EDIT_CLINICAL_NOTES:
			return {
				...state,
				isEdit: true,
				noteId: action.id,
			};

		case DRAFT_NOTES:
			return {
				...state,
				draftClinicalNotes: action.data,
			};
		case CLEAR_SAVED_NOTES:
			return {
				...state,
				saveClinicalNotes: '',
				saveHealthRecord: [],
				draftClinicalNotes: {},
				isEdit: false,
			};
		case FAILED_CLINICAL_NOTES:
			return {
				...state,
				clinicalNotesShow: false,
				coachNotesInitialFetch: false,
				doctorNotesInitialFetch: false,
			};

		case CLEAN_CLINICAL_NOTES:
			return {
				...state,
				patientId: 0,
				fetching: false,
				isEdit: false,
				statusCode: 0,
				totalResults: 0,
				patientDetails: {},
				healthRecord: {},
				clinicalNotes: {
					coach: [],
					doctor: [],
				},
				labTest: {
					allReports: [],
					totalResults: 0,
					offset: 0,
				},
				abnormalityAllReport: {
					allReports: [],
					totalResults: 0,
					offset: 0,
				},
				vitalsAllReport: {
					allReports: [],
					totalResults: 0,
					offset: 0,
				},
				coachTotalRecord: 0,
				doctorTotalRecord: 0,
				coachLastRecord: 0,
				doctorLastRecord: 0,
				noOfResults: 0,
				saveHealthRecord: [],
				saveClinicalNotes: {},
				draftClinicalNotes: {},
				error: {},
			};
		case CLEAR_VALIDATION:
			return {
				...state,
				statusCode: 0,
				isMessage: false,
				messageType: '',
				messageContent: {},
			};

		default:
			return state;
	}
}

export default clinicalNotesReducer;
