import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

import { getTwilioToken } from '../chat.state.selectors';
import {
	fetchChannels,
	saveChatObject,
	memberIsTyping,
	fetchTwilioToken,
	saveChatToken,
	saveLastMessageDetails,
	removeChannel,
	addNewUser,
	reArrangeChannels,
} from '../chat.store';

import ChatService from '../chat.service';

function useUsers(isLoggedIn) {
	const dispatch = useDispatch();
	const [clientChat, setClientChat] = useState(null);

	const twilioToken = useSelector(({ ChatStore }) => getTwilioToken(ChatStore));

	function subscribeToListeners(chatObject) {
		const Chat = chatObject || clientChat;
		if (Chat) {
			Chat.on('typingStarted', (member) => {
				console.log('CHAT on', member);
				dispatch(memberIsTyping(member, true));
			});
			Chat.on('typingEnded', (member) => {
				dispatch(memberIsTyping(member, false));
			});
			Chat.on('tokenAboutToExpire', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					const { twilioToken = '' } = data;
					// console.log('------ Twilio: tokenAboutToExpire -------------', twilioToken);
					Chat.updateToken(twilioToken);
				} catch (error) {
					console.log('------ Twilio: ERROR om tokenAboutToExpire -------------');
				}
			});
			Chat.on('tokenExpired', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					dispatch(saveChatToken(data));
					// console.log('------ Twilio: Expired -------------', data);
					const { twilioToken = '' } = data;
					Chat.updateToken(twilioToken);
				} catch (error) {
					console.log('------ Twilio: ERROR om tokenExpired -------------');
				}
			});
			Chat.on('messageRead', async (messageInfo) => {
				console.log('I am worked as message Read', messageInfo);
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageUpdated', async (messageInfo) => {
				console.log('I am message update');
				await dispatch(saveLastMessageDetails(messageInfo));

				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageRemoved', async (messageInfo) => {
				console.log('I am message removed');
				await dispatch(saveLastMessageDetails(messageInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('messageAdded', async (messageInfo) => {
				console.log('I am message add');
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('channelAdded', async (channel) => {
				console.log('I am channel added');
				await dispatch(addNewUser(channel));
				dispatch(reArrangeChannels());
			});
			// Chat.on('conversationAdded', async (channel) => {
			// 	await dispatch(addNewUser(channel));
			// });
			Chat.on('memberLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('participantLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				dispatch(reArrangeChannels());
			});
		}
	}

	async function initiateChat() {
		try {
			dispatch(fetchChannels(true));
			const chat = await ChatService.initializeChat(twilioToken);
			const channels = await ChatService.getSubscribedChannels({ limit: 50 });
			dispatch(saveChatObject(chat, channels));
			setClientChat(chat);
			subscribeToListeners(chat);
		} catch (error) {
			console.log(' ******* CHAT ERROR ******* ', error, error.response);
			setClientChat(null);
			dispatch(fetchChannels(false));
			dispatch(saveChatObject(null));
		}
	}

	// eslint-disable-next-line
	const memomizedCall = useCallback(() => {
		initiateChat();
	});

	useEffect(() => {
		if (isLoggedIn && twilioToken) memomizedCall();
		return () => {
			console.log('##### UNMOUNTING USEUSERS HOOKS ##### ');
		};
		// eslint-disable-next-line
	}, [twilioToken]);

	return clientChat;
}

export default useUsers;
