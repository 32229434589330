import { GET, PATCH, POST } from '../../service/HttpService';

import { isUnauthorized } from '../Login/login.store';
import { showSnackbar } from '../../components/Snackbar/snackbar.store';

import { snackBarErrorTypes } from '../../helpers/Constants';
import { extractMessageFrom } from '../../helpers/Utilities';

const FILE = 'FILE://LOGIN';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_INSULIN_LISTS = generateActionType('GET_INSULIN_LISTS');
const SAVE_INSULIN_LISTS = generateActionType('SAVE_INSULIN_LISTS');
const CLEAR_PATIENT_LISTS = generateActionType('CLEAR_PATIENT_LISTS');
const BULK_PATIENT_IMPORT = generateActionType('BULK_PATIENT_IMPORT');
const RESET_BULK_IMPORT_PROGRESS = generateActionType('RESET_BULK_IMPORT_PROGRESS');
const UPDATE_PATIENT_LIST = generateActionType('UPDATE_PATIENT_LIST');

// Action Creators
export async function getPatientList(args, url, headers = {}) {
	const patientConfig = { params: { ...args }, url: url || '/patients', headers };
	const response = await GET(patientConfig);
	return response;
}

export async function getDoctorList(args, url, headers = {}) {
	const doctorConfig = { params: { ...args }, url: url || '/doctors', headers };
	const response = await GET(doctorConfig);
	return response;
}

export async function getTemplate() {
	const templateConfig = { url: '/invite/csv-template' };
	const response = await GET(templateConfig);
	return response;
}

export async function specialCategory({ patientId, isSpecialCategory }) {
	const patientConfig = { data: { isSpecialCategory }, url: `/patients/${patientId}/special` };
	const response = await PATCH(patientConfig);
	return response;
}

export async function bulkInvite({ data }) {
	const importConfig = { data, url: `/invite/import` };
	const response = await POST(importConfig);
	return response;
}

export async function fileUpload(args) {
	const uploadConfig = {
		...args,
		appConfig: { baseURL: '', doNotNeedAuthorizationHeader: true },
		headers: { 'Content-Type': 'Multipart/form-data' },
	};
	const response = await POST(uploadConfig);
	return response;
}

export function resetProgress() {
	return { type: RESET_BULK_IMPORT_PROGRESS };
}

export function updatePatientList(data) {
	return { type: UPDATE_PATIENT_LIST, data };
}

async function getInsulinList(params) {
	const importConfig = { params, url: `/insulin-intakes/patients` };
	const response = await GET(importConfig);
	return response;
}

// const formatInsulinList = (data) => {
// 	const insulinObj = [];
// 	data.forEach((insulin) => {
// 		const insulinObj = {};
// 		insulin.insulinIntakes.forEach((insulinIntake)=>{

// 		})

// 	});
// };

export function getPatients({ override, ...args }, headers = {}) {
	console.log('Arg', override, args, headers);
	return async function (dispatch) {
		dispatch({ type: GET_INSULIN_LISTS, fetch: true });
		try {
			console.log('I AM CALLED TRY');
			const { data = {} } = await getInsulinList({
				fromDate: args.fromDate,
				toDate: args.toDate,
				search: args.search,
			});
			const dataSheet = data;
			console.log('I AM CALLED TRY', dataSheet);
			dispatch({ type: SAVE_INSULIN_LISTS, data: dataSheet, override });
		} catch (error) {
			console.log('getPatients error = ', error);
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: GET_INSULIN_LISTS, fetch: false });
		}
	};
}

export function markSpecialCategory({ limitersConfig, selectedPatient }) {
	return async function (dispatch) {
		dispatch({ type: GET_INSULIN_LISTS, fetch: true });
		try {
			await specialCategory(selectedPatient);
			dispatch(getPatients(limitersConfig));
		} catch (error) {
			dispatch(getPatients(limitersConfig));
			if (isUnauthorized(dispatch, error)) return;
			console.log('markSpecialCategory error = ', error);
		}
	};
}

export function uploadPatientDetails({ doctorUid, file }) {
	return async function (dispatch) {
		try {
			dispatch({ type: BULK_PATIENT_IMPORT, inProgress: true, completed: false });
			const awsConfig = {
				url: 'invite/files',
				data: { filename: 'patients.csv', contentType: 'string' },
			};
			const { data: { fields = {}, url } = {} } = await POST(awsConfig);

			const { key } = fields;
			const formData = new FormData();

			Object.keys(fields).forEach((key) => {
				formData.append(key, fields[key]);
			});

			formData.append('file', file);

			await fileUpload({ data: formData, url });
			await bulkInvite({ data: { doctorUid, csvKey: key } });
			dispatch({ type: BULK_PATIENT_IMPORT, inProgress: false, completed: true });
			dispatch(
				showSnackbar(
					'The file has been uploaded and is being processed.',
					snackBarErrorTypes.success
				)
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ type: BULK_PATIENT_IMPORT, inProgress: false, completed: false });
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));

			console.log('uploadPatientDetails error = ', error, doctorUid);
		}
	};
}

export function clearPatients() {
	return async function (dispatch) {
		dispatch({ type: CLEAR_PATIENT_LISTS, fetch: true });
	};
}

// Reducer
const initialState = {
	fetching: false,
	lists: [],
	noOfResults: 0,
	totalResults: 0,
	offset: 0,
	error: {},
	bulkUpload: {
		inProgress: false,
		completed: false,
	},
};

function insulinReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_PATIENT_LIST: {
			const { patientId, isSpecial } = action.data;
			const { lists = [] } = state;
			const patient = lists && lists.find((x) => x.patientId === patientId);
			const patientIndex = lists && lists.findIndex((x) => x.patientId === patientId);

			if (patient && patientIndex >= 0) {
				lists[patientIndex] = {
					...patient,
					DoctorUserMap: { ...patient.DoctorUserMap, isSpecialCategory: isSpecial },
					isSpecialCategory: isSpecial,
				};
			}

			return {
				...state,
				lists,
			};
		}
		case RESET_BULK_IMPORT_PROGRESS:
			return {
				...state,
				bulkUpload: {
					...state.bulkUpload,
					inProgress: false,
					completed: false,
				},
			};
		case BULK_PATIENT_IMPORT:
			return {
				...state,
				bulkUpload: {
					...state.bulkUpload,
					inProgress: action.inProgress,
					completed: action.completed,
				},
			};
		case GET_INSULIN_LISTS:
			return {
				...state,
				fetching: action.fetch,
			};
		case SAVE_INSULIN_LISTS: {
			const { data = [], override } = action;
			// const { results = [], noOfResults = 0, totalResults = 0, offset = 0 } = data;
			console.log('I AM CALLED', data);
			return {
				...state,
				fetching: false,
				noOfResults: 1,
				totalResults: 1,
				offset: 0,
				lists: override ? data : state.lists.concat(data),
			};
		}
		case CLEAR_PATIENT_LISTS:
			return {
				...state,
				fetching: false,
				lists: [],
				noOfResults: 0,
				totalResults: 0,
				offset: 0,
			};

		default:
			return state;
	}
}

export default insulinReducer;
