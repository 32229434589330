import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';
import FormLabel from '../formGroup/formLabel';

const Select = styled.select`
	display: inline-block;
	width: 100%;
	color: ${colors.BlueBase} !important;
	background: ${colors.White};
	border: 1px solid ${colors.BorderColorTB};
	border-radius: 4px;
	height: 52px;
	padding: 16px;
	font-size: 14px;
	transition: ease-in all 0.4s;
	box-sizing: border-box;
	font-family: ${fontMedium.familyName};
	border-color: ${(props) => props.error && colors.Red};
	border-color: ${(props) => props.warning && colors.Yellow};
	background: ${(props) => props.disabled && colors.Grey100};
	opacity: ${(props) => props.disabled && '.5'};
	pointer-events: ${(props) => props.disabled && 'none'};
	option {
		padding: 10px;
	}
	:focus {
		outline: none;
		border: 1px solid #8ba3e2;
		box-shadow: 0px 0px 5px ${colors.ShadowColorTB};
	}

	${({ floatingInput }) =>
		floatingInput &&
		`&:focus + ${FormLabel}, &:not(:placeholder-shown) ~ ${FormLabel} {
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
            color: ${colors.BlueBase};
          }
          &:focus, &:not(:placeholder-shown){
              border-color: ${colors.BlueBase};
          }`}
`;

export default Select;
