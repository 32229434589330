import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import avatar from '../../assets/images/avatar.png';

import { GreetingMessage, dateFormatter } from '../../helpers/Utilities';
import { dateSeparatorConfig, Roles, doctorLabel, RolesName } from '../../helpers/Constants';

import SidebarItem from './SideBarItem';
import AppSpinner from '../Spinner';
import {
	Sidebar,
	Header,
	HeaderLogo,
	HeaderRight,
	HeaderInfo,
	Icon,
	PageWrapper,
	InnerWrapper,
	Heading,
	Paragraph,
	Dropdown,
	DropdownList,
	DropdownListSepertor,
	Avatar,
} from '../../stories';
import BellIcon from '../../pages/DashBoard/components/Notifications/BellIcon';

function AppLayout(props) {
	const { children, appLogout = () => {}, layoutProps = {} } = props;
	const { innerWrapper = {} } = layoutProps;

	const userDetails = useSelector(({ AuthStore }) => {
		const { userDetails = {} } = AuthStore;
		return userDetails;
	});

	const { role = '', name, friendlyName, profile = avatar, showLoader = false } = userDetails;

	const [userProfile, setUserProfile] = useState(profile);
	const viewProfile = [Roles.doctor, Roles.coach, Roles.clinicAdmin, Roles.customerSupport];
	const greetings = GreetingMessage();
	const profileName =
		role === Roles.doctor
			? `${doctorLabel}${name || friendlyName || 'No name'}`
			: `${name || friendlyName || 'No name'}`;

	return (
		<PageWrapper>
			{/* Sidebar */}
			<Sidebar open={false}>
				<SidebarItem />
			</Sidebar>
			{showLoader && <AppSpinner />}
			<InnerWrapper {...innerWrapper}>
				{/* Header */}
				<Header>
					<Link to="/dashboard">
						<HeaderLogo />
					</Link>

					<HeaderRight>
						{role !== Roles.customerSupport && <BellIcon />}
						<HeaderInfo>
							<section style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
								<img style={{ margin: '0 5px' }} src={greetings.image} alt={greetings.message} />
								<Heading>
									{greetings.message}, <span>{profileName}</span>
								</Heading>
							</section>
							<Paragraph headerNameColor>
								{dateFormatter(Date.now(), dateSeparatorConfig.L_D_M_Y, true)}, {RolesName[role]}
							</Paragraph>
						</HeaderInfo>
						<Dropdown
							open={false}
							name={
								<Avatar small rounded>
									<img
										src={userProfile || avatar}
										onError={() => setUserProfile(avatar)}
										alt="User Avatar"
									/>
								</Avatar>
							}
						>
							{viewProfile.includes(role) && (
								<DropdownList>
									<Link to="/profile">
										<Icon iconName="icon-Edit" /> Edit profile
									</Link>
								</DropdownList>
							)}
							{/* <DropdownList>
								<Icon iconName="icon-Settings" />
								Settings
							</DropdownList> */}
							<DropdownListSepertor />
							<DropdownList onClick={() => appLogout()}>
								<Icon iconName="icon-Logout" />
								Logout
							</DropdownList>
						</Dropdown>
					</HeaderRight>
				</Header>
				{children}
			</InnerWrapper>
		</PageWrapper>
	);
}

export default memo(AppLayout);
