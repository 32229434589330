import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

function cursorControl(cursor) {
	if (cursor === 'grab')
		return `&:hover {
			cursor: grab;
			cursor: -webkit-grab;
		}`;
	if (cursor === 'copy')
		return `&:hover {
			cursor: copy;
		}`;
	if (cursor === 'default')
		return `&:hover {
			cursor: default;
		}`;
	return null;
}

const Button = styled.button`
	color: ${colors.White};
	transition: ease-in all 0.4s;
	height: 52px;
	font-size: 18px;
	padding: 0.25em 25px;
	border-radius: 4px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	white-space: nowrap;
	transition: ease-in all 0.4s;
	width: ${(props) => (props.block ? '100%' : 'auto')};

	${({ navigationLeftButton }) =>
		navigationLeftButton &&
		`border: 1px solid #3263D6;
	border-radius: 50px !important;
padding: -43px;
	margin-right: 15px;
	min-width:20px !important;
	min-height:20px !important;
	width:20px !important;
	height:20px !important;
	:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLigBaht};
			color: ${colors.White}!important;
		}`}

	${({ navigationRightButton }) =>
		navigationRightButton &&
		`border: 1px solid #3263D6;
	border-radius: 50px !important;
padding: -43px;
	margin-left: 15px;
	min-width:20px !important;
	min-height:20px !important;
	width:20px !important;
	height:20px !important;
	:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLight};
			color: ${colors.White}!important;
		}`}

	${({ nextPreButton }) =>
		nextPreButton &&
		`color: ${colors.White};
	transition: ease-in all 0.4s;
	height: 52px;
	font-size: 18px;
	padding: 0.25em 25px;
	border-radius: 4px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	transition: ease-in all 0.4s;
	width: ${(props) => (props.block ? '100%' : 'auto')};
	`}

	${({ disabled }) =>
		disabled &&
		`cursor: default;
		pointer-events: none;
		opacity: 0.5;
	`}
	${({ btnResponsive }) =>
		btnResponsive &&
		`@media screen and (max-width: 550px) {
			width:100%;
		}
	`}
	:hover {
		cursor: pointer;
		outline: none;
	}
	:focus {
		outline: none;
	}
	margin: ${(props) => props.margin};
	margin-left: ${(props) => props.marginLeft};
	${({ primary }) =>
		primary &&
		`background: ${colors.BlueBase};
		border:1px solid ${colors.BlueBase};
		:hover{
			background: ${colors.BlueLight} !important;
			border:1px solid ${colors.BlueLight} !important;
		}
	`}
	${({ danger }) =>
		danger &&
		`background: ${colors.Red1};
		border:1px solid ${colors.Red1};
		:hover{
			background: ${colors.Red} !important;
			border:1px solid ${colors.Red} !important;
		}
	`}
	${({ primaryBorder }) =>
		primaryBorder &&
		`background: ${colors.White};
		border:1px solid ${colors.BlueBase};
		color: ${colors.BlueBase}!important;
		font-family: ${fontMedium.familyName};
		:hover{
			background: ${colors.BlueLight};
			border:1px solid ${colors.BlueLight};
			color: ${colors.White}!important;
		}
	`}
	${({ dangerBorder }) =>
		dangerBorder &&
		`background: ${colors.White};
		border:1px solid ${colors.Red};
		color: ${colors.Red}!important;
		font-family: ${fontMedium.familyName};
		:hover{
			background: ${colors.Red};
			border:1px solid ${colors.Red};
			color: ${colors.White}!important;
		}
	`}
	${({ primaryTextIcon }) =>
		primaryTextIcon &&
		`background: ${colors.White};
		border:0px;
		color: ${colors.BlueLight}!important;
		font-family: ${fontMedium.familyName};
		text-transform: none;
		font-size: 16px;
		height: 30px;
		:hover{
			background: ${colors.White};
			border:0px;
			color: ${colors.BlueBase}!important;
		}
		span{
			margin-right:8px;
			font-size: 18px;
		}
	`}
	${({ dangerTextIcon }) =>
		dangerTextIcon &&
		`background: ${colors.White};
		border:0px;
		color: ${colors.Red}!important;
		font-family: ${fontMedium.familyName};
		text-transform: none;
		font-size: 16px;
		height: 30px;
		:hover{
			background: ${colors.White};
			border:0px;
			color: #FCB216 !important;
			transform: scale(1.1);
		}
		span{
			margin-right:8px;
			font-size: 18px;
		}
	`}
	${({ primaryTextFilterIcon }) =>
		primaryTextFilterIcon &&
		`background: ${colors.White};
		border:0px;
		padding: 0.25em 10px !important;
		color: ${colors.BlueLight}!important;
		font-family: ${fontMedium.familyName};
		text-transform: none;
		font-size: 16px;
		height: 30px;
		:hover{
			background: ${colors.White};
			border:0px;
			color: ${colors.BlueBase}!important;
		}
		span{
			margin-right:8px;
			font-size: 18px;
		}
	`}
	${({ mwButton }) =>
		mwButton &&
		`min-width: 140px;
	`}
	${({ secondary }) =>
		secondary &&
		`background: ${colors.White};
		border: 1px solid ${colors.Grey100};
		color: ${colors.Grey8c};
		:hover{
			background: ${colors.Grey100};
		}
	`}
	${({ success }) =>
		success &&
		`background: ${colors.GreenBase};
		border: 1px solid ${colors.GreenBase};
		color: ${colors.White};
		:hover{
			background: ${colors.GreenDark};
		}
	`}

	${({ blueButton }) =>
		blueButton &&
		`background: ${colors.BlueLight};
		border: 1px solid ${colors.BlueLight};
		color: ${colors.White};
		:hover{
			background: ${colors.BlueLightDark};
		}
	`}

	${({ iconButton }) =>
		iconButton &&
		`width:48px;
		height:48px;
		min-width:48px;
		min-height:48px;
		border-radius: 4px;
		font-size: 22px;
		padding:0px;
	`}
	${({ iconButtonSM }) =>
		iconButtonSM &&
		`width:30px;
		height:30px;
		min-width:30px;
		min-height:30px;
		border-radius: 0px;
		font-size: 18px;
		padding:0px;
		background: transparent;
	`}
	${({ iconButtonShared }) =>
		iconButtonShared &&
		`
		span{
			color: ${colors.GreenBase};
			font-size: 16px;
			transition: ease-in all 0.4s;
		}
		// &:hover{
		// 	span{
		// 		color: ${colors.GreyC4};
		// 	}
		// }
	`}
	${({ iconButtonNonShared }) =>
		iconButtonNonShared &&
		`
		span{
			color: ${colors.GreyC4};
			transition: ease-in all 0.4s;
			font-size: 16px;
		}
		&:hover{
			span{
				color: ${colors.GreenBase};
			}
		}
	`}
	${({ iconButtonSMTL }) =>
		iconButtonSMTL &&
		`width:30px;
		height:30px;
		min-width:30px;
		min-height:30px;
		border-radius: 0px;
		padding:0px;
		background: transparent;
	`}
	${({ iconPrimary }) =>
		iconPrimary &&
		`color: ${colors.BlueBase};
		&:hover{
			color: ${colors.BlueLight}
		}
	`}
	${({ iconSecondary }) =>
		iconSecondary &&
		`color: ${colors.Grey87};
		&:hover{
			color: ${colors.BlueBase}
		}
	`}

	${({ roundButton }) =>
		roundButton &&
		`border-radius:100%;
	`}

	${({ shadowButton }) =>
		shadowButton &&
		`box-shadow: 0px 4px 4px rgba(83, 109, 254, 0.25);
	`}
	${({ smallButton }) =>
		smallButton &&
		`padding: 6px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		span{
			font-size: 11px;
			padding-right:6px;
		}
	`}
	${({ smallButtonS }) =>
		smallButtonS &&
		`padding: 10px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		span{
			font-size: 11px;
			padding-right:6px;
		}
	`}
	${({ ButtonIconSmall }) =>
		ButtonIconSmall &&
		`font-size: 14px;
	`}
	${({ ButtonWhite }) =>
		ButtonWhite &&
		`padding: 6px 15px;
		height: auto;
		font-size: 14px;
		text-transform: none;
		color: rgba(15, 37, 95, 0.5);
		background: ${colors.White};
		+ button {
			margin-left:10px;
		}
		&:hover{
			background: #E1E5ED;
			color: ${colors.BlueBase};
			font-family: ${fontMedium.familyName};
		}
	`}
	${({ ButtonActive }) =>
		ButtonActive &&
		`background: #E1E5ED;
		color: ${colors.BlueBase};
		font-family: ${fontMedium.familyName};
	`}
	${({ ButtonHeightSmall }) =>
		ButtonHeightSmall &&
		`height: 40px;
		font-size: 15px;
	`}
	${({ ButtonLink }) =>
		ButtonLink &&
		`background: transparent;
		padding: 0;
		height: auto;
		width: auto;
		padding-right:5px;
		color: ${colors.BlueLight};
		font-size: 13px;
		line-height: 15px;
		text-transform: none;
		&:hover{
			color: ${colors.BlueBase};
		}
		span{
			font-size: 9px;
			position: relative;
			right: -6px;
		}
	`}
	${({ pointer }) => pointer && cursorControl(pointer)}
`;

export default Button;
