import { GET, POST, PUT, DELETE } from '../../service/HttpService';
import { errorHandling, showMessage } from '../../components/Toast/toast.store';
// import { PER_PAGE } from '../../helpers/Constants';

import { isUnauthorized } from '../Login/login.store';

const FILE = 'FILE://HEALTH-CARD';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_LIST = generateActionType('GET_LIST');
const GET_CATEGORY = generateActionType('GET_CATEGORY');

const GET_DETAILS = generateActionType('GET_DETAILS');
const BEFORE_GET_LIST = generateActionType('BEFORE_GET_LIST');
const GET_LIST_PAGINATION = generateActionType('GET_LIST_PAGINATION');
const STORE_KEYWORDS = generateActionType('STORE_KEYWORDS');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_ALL = generateActionType('CLEAR_ALL');
const STORE_PARAMS = generateActionType('STORE_PARAMS');

// Reducer
const initialState = {
	initialList: true,
	fetching: false,
	dataLoader: true,
	detailsFetching: false,
	list: [],
	details: {},
	listOffset: 0,
	keywords: '',
	params: [],
	statusCode: 0,
	noOfResults: 0,
	totalResults: 0,
	category: {
		results: [],
		fetching: true,
	},
	template: {
		results: [],
		fetching: true,
	},
};

export function clearAll() {
	return function (dispatch) {
		dispatch({ type: CLEAR_ALL });
	};
}

export function searchKeyword(searchKeywords = '') {
	return function (dispatch) {
		dispatch({ type: STORE_KEYWORDS, keyword: searchKeywords });
	};
}

export function setParamsAction(params = []) {
	return function (dispatch) {
		dispatch({ type: STORE_PARAMS, params });
	};
}

export function getCards(data = {}) {
	const {
		// limit = PER_PAGE,
		limit = 1000,
		offset = 0,
		pagination = false,
		searchKeyword = '',
		initialList = true,
		params = '',
	} = data;

	const initialLists = initialList;

	const getConfig = { params: { ...params }, url: `cards?limit=${limit}&offset=${offset}` };
	return async function (dispatch) {
		try {
			dispatch({ type: STORE_KEYWORDS, searchKeyword });
			// initialLists = !pagination;
			const type = pagination ? GET_LIST_PAGINATION : GET_LIST;

			const { data = {} } = await GET(getConfig);
			const { cards = [], noOfResult = 0, totalResults = 0 } = data;
			dispatch({
				type,
				data: cards,
				noOfResult,
				totalResults,
				offset,
				initialList: initialLists,
				dataLoader: false,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({
				type: GET_LIST,
				data,
				listOffset: 0,
				initialList: true,
				dataLoader: false,
			});
		}
	};
}

export function getDetails(id = 0) {
	const getConfig = { url: `coachs/${id}` };
	const detailsFetching = true;
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_DETAILS, data, detailsFetching });
		}
	};
}

export function getCategories() {
	const getConfig = { url: `cards/app-config` };
	return async function (dispatch) {
		try {
			const { data = {} } = await GET(getConfig);
			dispatch({ type: GET_CATEGORY, data });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			const data = {};
			dispatch({ type: GET_CATEGORY, data });
		}
	};
}

export function create(datas = {}) {
	const postConfig = { data: datas, url: `cards` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await POST(postConfig);
			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully added', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function edit(datas = {}, id = 0) {
	const filterDatas = datas;

	const postConfig = { data: filterDatas, url: `cards/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await PUT(postConfig);

			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Record successfully updated', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export function deleteCard(id = 0) {
	const delConfig = { url: `cards/${id}` };
	return async function (dispatch) {
		try {
			const { status = 0 } = await DELETE(delConfig);

			dispatch({ type: GET_STATUS, status });
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: {
						0: { message: 'Card successfully deleted', key: '' },
					},
				})
			);
			dispatch(getCards());
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export async function fileUpload(image = {}) {
	const { type = '', name = '' } = image;
	if (type === '') return '';
	const config = {
		// type: 'profile',
		filename: name,
		contentType: type,
	};

	const awsConfig = { url: 'cards/files', data: config };
	const { data = {} } = await POST(awsConfig);
	const { fields } = data;
	const { key } = fields;
	const formData = new FormData();

	Object.keys(fields).forEach((key) => {
		formData.append(key, fields[key]);
	});

	formData.append('file', image);
	await POST({
		url: data.url,
		data: formData,
		appConfig: { baseURL: '', doNotNeedAuthorizationHeader: true },
		headers: { 'Content-Type': 'Multipart/form-data' },
	});

	return key;
}

function healthCardReducer(state = initialState, action) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case STORE_KEYWORDS:
			return {
				...state,
				keywords: action.searchKeyword,
			};
		case STORE_PARAMS:
			return {
				...state,
				params: action.params,
			};
		case GET_CATEGORY: {
			const { templates = [], categories = [], themes = [] } = action.data;
			return {
				...state,
				category: {
					results: categories,
					fetching: false,
				},
				template: {
					results: templates,
					fetching: false,
				},
				themes,
			};
		}

		case BEFORE_GET_LIST:
			return {
				...state,
				dataLoader: action.dataLoader,
			};
		case GET_LIST: {
			return {
				...state,
				list: action.data,
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: 0,
				noOfResult: 1,
				totalResults: action.totalResults,
				/* statusCode: 0, */
			};
		}
		case GET_LIST_PAGINATION: {
			const { data } = action;
			return {
				...state,
				list: [...state.list, ...data],
				initialList: action.initialList,
				dataLoader: action.dataLoader,
				fetching: true,
				listOffset: action.offset,
				noOfResults: action.noOfResults,
				totalResults: action.totalResults,
			};
		}
		case GET_DETAILS:
			return {
				...state,
				details: action.data,
				detailsFetching: action.detailsFetching,
			};
		case CLEAR_ALL:
			return {
				...state,
				statusCode: 0,
				listOffset: 0,
				keywords: '',
				params: [],
			};

		default:
			return state;
	}
}

export default healthCardReducer;
